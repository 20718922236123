import { Column, Row, Spacer } from '@smu-chile/pkg-unimarc-components'
import { HeadTotalCount } from './HeadTotalCount'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface BodyTitleProps {
  hideMenu?: boolean
  isLoading?: boolean
  title?: string
  handleOpenMyListsModal?: () => void
}

export const BodyTitle = ({
  hideMenu,
  isLoading = false,
  title,
  handleOpenMyListsModal
}: BodyTitleProps): React.ReactElement => {
  return (
    <Column
      position='fixed'
      zIndex={getGlobalStyle('--z-index-20')}
    >
      <Row alignItems='center'>
        <HeadTotalCount
          handleOpenMyListsModal={handleOpenMyListsModal}
          hideMenu={hideMenu}
          isLoading={isLoading}
          title={title}
        />
      </Row>
      <Spacer.Horizontal size={8} />
    </Column>
  )
}
