import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'

export interface PriceProps {
  isLoading: boolean
  price?: string | number
  productDiscount?: string
}

export const Price = ({
  isLoading = false,
  price = '',
  productDiscount
}: PriceProps): React.ReactElement<PriceProps> => {
  const { isMobile } = useMobile()
  return (
    <Container
      alignItems='start'
      isWrap
    >
      <Row
        alignItems='center'
        justifyContent='start'
      >
        <Column
          alignItems='start'
          id={getBemId('shelfPrice', 'container')}
          justifyContent='center'
          margin='0'
          width='auto'
        >
          <Row alignItems='center'>
            <Container minWidth='max-content'>
              <Text
                color='primary'
                fontSize={isMobile ? 'sm' : 'lg'}
                fontWeight='medium'
              >
                {`${
                  productDiscount && !isLoading ? productDiscount : price
                } c/u`}
              </Text>
            </Container>
            {productDiscount && !isLoading && (
              <Container margin='0 0 0 4px'>
                <Icon
                  customSize={16}
                  name='Unipay2'
                />
              </Container>
            )}
          </Row>
          {productDiscount && !isLoading && (
            <>
              <Spacer.Horizontal size={isMobile ? 1 : 2} />
              <Text
                color='black'
                fontSize={isMobile ? 'xs' : 'md'}
                fontWeight='medium'
              >
                {`${price} c/u`}
              </Text>
            </>
          )}
        </Column>
      </Row>
    </Container>
  )
}
