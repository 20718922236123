type Position = 'absolute' | 'relative' | 'fixed' | 'initial' | 'sticky'

interface IGlobalConfig {
  position: Position
  topMobile: string
  height: string
  maxWidth: string
  width: string
  rightMobile: string
  leftMobile: string
  sizeAlertModalIcon: number
  paddingModal: string
}

export const globalConfig = (isMobile: boolean): IGlobalConfig => {
  return {
    position: isMobile ? 'relative' : 'sticky',
    topMobile: isMobile ? '62px' : '92px',
    height: '56px',
    maxWidth: '377px',
    width: isMobile ? '92%' : '377px',
    rightMobile: isMobile ? '0px' : '140px',
    leftMobile: isMobile ? '0px' : '',
    sizeAlertModalIcon: isMobile ? 28 : 24,
    paddingModal: isMobile ? '8px 16px' : '12px 12px 12px 8px'
  }
}
