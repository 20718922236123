import {
  MobileModalWrapper,
  Container,
  Column,
  Spacer,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IRestockedProducts } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostStockAvailability'
import { ProductShelf } from '../ProductShelf'
import { RestockedModalButton } from '../RestockedModalButton'
import styles from '../../MyUsualsAndFavoritesWrapper/MyUsualsAndFavoritesWrapper.module.css'

interface RestockedModalMobileProps {
  restockedProducts: IRestockedProducts
  handleClose: () => void
}

export const RestockedModalMobile = ({
  restockedProducts,
  handleClose
}: RestockedModalMobileProps) => {
  return (
    <MobileModalWrapper
      blockId={getBemId('restockedModal', 'container')}
      body={
        <Column padding='16px'>
          <Row id={getBemId('restockedModal', 'title')}>
            <Text
              fontSize='md'
              textAlign='center'
            >
              Estos productos volvieron a estar disponibles, los agregaremos a
              tu carro.
            </Text>
          </Row>
          <Spacer.Horizontal customSize={20} />
          {restockedProducts.map(
            ({ id, name, image, measurementUnit, quantity }) => {
              return (
                <Container
                  isWrap
                  key={id}
                  minWidth='100%'
                  padding='0 16px'
                >
                  <ProductShelf
                    img={image}
                    isMobile={true}
                    measurement={measurementUnit}
                    name={name}
                    quantity={quantity}
                  />
                  <Spacer.Horizontal customSize={10} />
                </Container>
              )
            }
          )}
        </Column>
      }
      bodyContainerProps={{
        padding: '0'
      }}
      customFooter={
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          customClassName={styles.customBorderTop}
          justifyContent='center'
          padding='16px'
          zIndex={getGlobalStyle('--z-index-5')}
        >
          <RestockedModalButton handleClose={handleClose} />
        </Container>
      }
      dragProps={{
        draggButton: false
      }}
      footerContainerProps={{
        padding: '0'
      }}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        customSize: 20
      }}
      headerProps={{
        padding: '6px 16px 20px',
        minHeight: '48px',
        customClassName: styles.customHeader
      }}
      headerTitle='Están de vuelta'
      hideHeaderDivider={false}
      iconColor={getGlobalStyle('--color-base-black')}
      iconSize={20}
      isEnableButton
      modalConfigsProps={{
        openAnimation: true,
        fullScreenSticky: true,
        hiddenX: true,
        isAutoHeight: true,
        isOpen: true,
        isWindowBlocked: true,
        marginFullScreen: '1vh 0 0',
        stickyBottom: true,
        toggle: handleClose,
        toggleOutside: handleClose
      }}
      onClose={handleClose}
      styleProps={{
        padding: '0',
        height: '100%',
        borderRadius: `${getGlobalStyle('--border-radius-md')} ${getGlobalStyle(
          '--border-radius-md'
        )} 0 0`
      }}
    />
  )
}
