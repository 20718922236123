import {
  Column,
  Picture,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export interface ContentModalCleanCartProps {
  garlicImage: string
  isMobile: boolean
}

export const ContentModalCleanCart = ({
  garlicImage,
  isMobile
}: ContentModalCleanCartProps) => {
  const heightPicture = isMobile ? '194px' : '130px'
  const widthPicture = isMobile ? '310px' : '198px'

  return (
    <Column
      alignItems='center'
      justifyContent='center'
      margin='32px 0 0 0'
    >
      <Picture
        height={heightPicture}
        id={getBemId('emptyCartModal', 'image')}
        src={garlicImage}
        width={widthPicture}
      />
      <Spacer.Horizontal size={16} />
      <Spacer.Horizontal size={4} />
      <Text
        fontSize='xl'
        fontWeight='semibold'
        id={getBemId('emptyCartModal', 'title')}
        textAlign='center'
      >
        Vaciar el carro
      </Text>
      <Spacer.Horizontal size={16} />
      <Spacer.Horizontal size={4} />
      <Text
        id={getBemId('emptyCartModal', 'subtitle')}
        lineHeight='normal'
        textAlign='center'
      >
        ¿Estás seguro que quieres eliminar todos los productos de tu carro?
      </Text>
      <Spacer.Horizontal size={16} />
    </Column>
  )
}
