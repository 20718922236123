import { Column, Picture } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export interface AddressIconProps {
  iconSize: string
  srcImage: string
}

export const AddressIcon = ({
  iconSize,
  srcImage
}: AddressIconProps): React.ReactElement => {
  return (
    <Column maxWidth={`${iconSize}px`}>
      <Picture
        alt='Order'
        height={iconSize}
        id={getBemId('addressSelected', 'icon')}
        src={srcImage}
        width={iconSize}
      />
    </Column>
  )
}
