import {
  Column,
  Container,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  currencyFormat,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { timeUTCFilter } from 'components/MyShipments/helpers/timeUTCFilter'
import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import styles from '../../MyShipments.module.css'

export interface SquareButtonProps {
  date: IAvailableWindows
  endDate: string
  index?: number
  isMobile: boolean | string
  price: number
  scheduleSelected: IAvailableWindows
  startDate: string
  handleSchedule: (date: IAvailableWindows) => void
}

export const SquareButton = ({
  date,
  endDate,
  index,
  isMobile,
  price,
  scheduleSelected,
  startDate,
  handleSchedule
}: SquareButtonProps): React.ReactElement => {
  const startTime = new Date(startDate)
  const endTime = new Date(endDate)
  const formatedPrice = currencyFormat({
    number: price ?? 0,
    bool: false
  })
  return (
    <Container
      border={`0.3px solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius={getGlobalStyle('--border-radius-sm')}
      clickable='pointer'
      customClassName={
        scheduleSelected?.startDateUtc === startDate &&
        scheduleSelected?.endDateUtc === endDate
          ? styles.optionSelected
          : ''
      }
      id={getBemId('shipmentHour', 'hour', index?.toString())}
      maxHeight={isMobile ? '73px' : '80px'}
      maxWidth={isMobile ? '99px' : '140px'}
      onClick={() => {
        return handleSchedule(date)
      }}
    >
      <Column
        alignItems='center'
        clickable='pointer'
        customHeight={isMobile ? '73px' : '80px'}
        customWidth={isMobile ? '99px' : '140px'}
        justifyContent='center'
        minHeight={isMobile ? '73px' : '80px'}
        minWidth={isMobile ? '99px' : '140px'}
      >
        <Text
          clickable='pointer'
          fontSize={isMobile ? 'sm' : 'md'}
          fontWeight='semibold'
          id={getBemId('shipmentHour', 'hourText', index?.toString())}
        >
          {timeUTCFilter(startTime)} - {timeUTCFilter(endTime)}
        </Text>
        <Spacer.Horizontal size={8} />
        <Text
          clickable='pointer'
          fontSize={isMobile ? 'sm' : 'md'}
          fontWeight='regular'
          id={getBemId('shipmentHour', 'price', index?.toString())}
        >
          {formatedPrice === '$0' ? 'Gratis' : formatedPrice}
        </Text>
      </Column>
    </Container>
  )
}
