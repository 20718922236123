import React from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface TextComponentProps {
  message?: string
}

export const TextComponent = ({ message }: TextComponentProps) => {
  return (
    <Container>
      <Row
        alignItems='center'
        gap='8px'
      >
        <Column maxWidth='max-content'>
          <Icon
            color={getGlobalStyle('--color-feedback-success-light')}
            customSize={20}
            name='CheckCircle'
          />
        </Column>
        <Column>
          <Text
            color='black'
            fontSize='md'
            fontWeight='medium'
            id={getBemId('tooltipMessage', 'text')}
            lineHeight='15px'
            truncate={74}
          >
            {message}
          </Text>
        </Column>
      </Row>
    </Container>
  )
}

export default TextComponent
