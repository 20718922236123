/**
 * Format a given UTC date to display the time in HH:MM format.
 * @param {Date} date - The UTC date object.
 * @returns {string} The formatted time string.
 * @example
 * const utcDate = new Date("2024-03-01T09:30:00+00:00");
 * const formattedTime = timeUTCFilter(utcDate);
 * Output: "09:30"
 */
export const timeUTCFilter = (date: Date) => {
  return `${('0' + date.getUTCHours()).slice(-2)}:${(
    '0' + date.getUTCMinutes()
  ).slice(-2)}`
}
