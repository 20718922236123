import { timeUTCFilter } from 'components/MyShipments/helpers/timeUTCFilter'

/**
 * Formats a given date string to a specific format suitable for sending on a tag.
 * @param {string} startDateUtc - The start date string in UTC format.
 * @returns {string} The formatted date string in "YYYY-MM-DD HH:MM" format.
 * @example
 * Output: 2024-04-02 12:00
 * formatDateToSendOnTag("2024-04-02T12:12:00Z")
 */
export const formatDateToSendOnTag = (startDateUtc: string): string => {
  const startDate = new Date(startDateUtc)
  const year = startDate.getFullYear()
  const month = (startDate.getMonth() + 1).toString().padStart(2, '0')
  const day = startDate.getDate()
  const formatDay: number | string = day.toString().length < 2 ? '0' + day : day
  return `${year}-${month}-${formatDay} ${timeUTCFilter(startDate)}`
}
