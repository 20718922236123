import { useRouter } from 'next/router'
import {
  Button,
  Column,
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './ShipmentError.module.css'

export const ShipmentError = ({
  errorCode,
  shipmentErrorImage
}: {
  errorCode?: string
  shipmentErrorImage?: string
}): React.ReactElement => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const reload = () => {
    router.reload()
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-base-white')}
      isWrap
      justifyContent='center'
      minHeight={isMobile ? '100vh' : '90vh'}
      tagName='section'
    >
      <Column
        alignItems='center'
        customHeight='90vh'
        justifyContent='center'
        position='relative'
      >
        <Container
          customWidth='90'
          isWrap
          justifyContent='center'
          maxWidth='1440px'
        >
          <Picture
            height='200'
            id={getBemId('shipmentError', 'image')}
            src={shipmentErrorImage}
            width='200'
          />

          <Spacer.Horizontal size={isMobile ? 24 : 16} />

          <Column
            alignItems='center'
            justifyContent='center'
          >
            <Text
              fontSize={isMobile ? 'xl' : '2xl'}
              fontWeight='semibold'
              id={getBemId('shipmentError', 'title')}
              textAlign='center'
            >
              ¡Nos perdimos!
            </Text>
            <Spacer.Horizontal size={12} />
            <Row maxWidth='480px'>
              <Text
                fontSize={isMobile ? 'lg' : 'xl'}
                id={getBemId('shipmentError', 'subtitle')}
                textAlign='center'
              >
                En breve le ponemos fecha y hora, por favor ayúdanos recargando
                la página.
              </Text>
            </Row>
            {errorCode && (
              <>
                <Spacer.Horizontal size={12} />
                <Container
                  alignItems='center'
                  justifyContent='center'
                >
                  <Text
                    customColor={getGlobalStyle('--color-neutral-gray-silver')}
                    fontSize='md'
                    fontWeight='medium'
                    id={getBemId('shipmentError', 'errorCode')}
                    textAlign='center'
                  >
                    Error: {errorCode}
                  </Text>
                </Container>
              </>
            )}
            <BigScreen>
              <Spacer.Horizontal size={96} />
            </BigScreen>
          </Column>
        </Container>
        <Container
          alignItems='center'
          bottom='0'
          customClassName={styles.custom__top}
          justifyContent='center'
          maxHeight='40px'
          position={isMobile ? 'absolute' : 'initial'}
        >
          <Button
            borderRadius={getGlobalStyle('--border-radius-full')}
            fontSize={getGlobalStyle('--font-size-lg')}
            fontWeight={getGlobalStyle('--font-weight-normal')}
            height='40px'
            id={getBemId('shipmentError', 'button')}
            label='Recargar página'
            onClick={reload}
            type='primary-gray'
            width={isMobile ? '343px' : '408px'}
          />
        </Container>
      </Column>
    </Container>
  )
}
