import { SHORT_DAYS, SHORT_MONTH } from 'shared/utils/constants'
import { timeUTCFilter } from '../timeUTCFilter'

/**
 * Formats the delivery text based on start and end dates.
 * @param {string} startDateUtc - The start date in UTC format.
 * @param {string} endDateUtc - The end date in UTC format.
 * @returns {string} The formatted delivery text.
 * @example
 * const startDateUtc = "2024-03-01T09:00:00+00:00";
 * const endDateUtc = "2024-03-01T11:00:00+00:00";
 * const deliveryText = formatDeliveryText(startDateUtc, endDateUtc);
 * Output: "Mon 03/01, 09:00 - 11:00 hrs."
 */
export const formatDeliveryText = (
  startDateUtc: string,
  endDateUtc: string
) => {
  const startDate = new Date(startDateUtc)
  const endDate = new Date(endDateUtc)
  const currentDate: Date = new Date()

  const formatDate = (date: string) => {
    const newDateFromConstructor = new Date(date)
    const monthGotten = newDateFromConstructor.getMonth()
    const month = SHORT_MONTH[monthGotten]
    const day = newDateFromConstructor.getDate()

    const formatMonth: number | string =
      month.toString().length < 2 ? '0' + month : month
    const formatDay: number | string =
      day.toString().length < 2 ? '0' + day : day

    return [formatDay, formatMonth].join(' ')
  }

  const dayChanged =
    startDate.getDate() === currentDate.getDate()
      ? 'Hoy'
      : SHORT_DAYS[startDate.getDay()].slice(0, -1)

  return {
    dayFormated: `${dayChanged} ${formatDate(startDateUtc)}`,
    hoursFormated: `${timeUTCFilter(startDate)} - ${timeUTCFilter(endDate)}`
  }
}
