import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { ShelfDeleteItem } from '../Shelves/Shelf/ShelfDeleteItem'
import { ProductDetails } from '../Shelves/Shelf/ProductDetails'
import { ICartItemIssue } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import {
  BigScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface NotAvailableShelvesProps {
  bemId: string
  isLoadingByEvent: boolean
  isLoadingOnDelete: boolean
  isMobile?: boolean
  orderItem: ICartItemIssue
  handleDeleteProductsIssues: () => void
}

export const NotAvailableShelves = ({
  bemId,
  isLoadingByEvent,
  isLoadingOnDelete,
  isMobile,
  orderItem,
  handleDeleteProductsIssues
}: NotAvailableShelvesProps): React.ReactElement => {
  if (isLoadingOnDelete) {
    return (
      <Column padding={isMobile ? '8px' : '12px 16px'}>
        <Row>
          <Backbone
            borderRadius={getGlobalStyle('--border-radius-md')}
            height={isMobile ? 80 : 135}
          />
        </Row>
        <Spacer.Horizontal size={16} />
      </Column>
    )
  }

  return (
    <Row customHeight={isMobile ? '97px' : '138px'}>
      <Container
        id={bemId}
        isWrap
        minWidth='100%'
        padding={isMobile ? '8px' : '12px 16px'}
      >
        <BigScreen>
          <Column>
            <ShelfDeleteItem
              isLoading={isLoadingByEvent}
              isMobile={false}
              onDelete={handleDeleteProductsIssues}
            />
            <Spacer.Horizontal customSize={3} />
          </Column>
        </BigScreen>
        <Row>
          <ProductDetails
            img={orderItem?.image}
            isMobile={isMobile}
            isNotAvailableProduct={true}
            leftQuantity={orderItem?.leftQuantity}
            measurementUnit={orderItem?.measurementUnit}
            name={orderItem?.name}
          />
        </Row>
      </Container>
    </Row>
  )
}
