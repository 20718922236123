import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

/**
 * get promotions from the shelves.
 * @param {Object} param0 Object that contains the data.
 * @param {Object} param0.data API data.
 * @returns {Array} Shelve Promotions.
 */
export const promotionsOfShelves = ({ data }) => {
  // Get the campaigns from the shelf fields
  const getCampaignsFromShelves = ({ fields, data }) => {
    return fields?.campaings?.map(({ sys }) => {
      // Find the linked entry
      const linkedEntry = data?.includes?.Entry?.find((entry) => {
        return entry.sys.id === sys.id
      })
      const linkedEntryFields = linkedEntry?.fields || {}

      // If there is a linked campaign icon, get its fields
      if (linkedEntryFields.campaignIcon) {
        const linkedAsset = data?.includes?.Asset?.find((asset) => {
          return asset.sys.id === linkedEntryFields.campaignIcon.sys.id
        })
        if (linkedAsset) {
          linkedEntryFields.campaignIcon = linkedAsset.fields
        }
      }

      return { id: sys.id, ...linkedEntryFields }
    })
  }

  // Filter campaigns based on format
  const filterCampaignsByFormat = (campaigns) => {
    return campaigns
      .map((fields) => {
        if (fields.idFormato === 1) {
          return {
            ...fields
          }
        }
        return null
      })
      .filter((item) => {
        return item !== null
      })
  }

  // Get shelf campaigns
  const shelvesCampaigns = data?.items?.map(({ fields }) => {
    return getCampaignsFromShelves({ fields, data })
  })

  // Filter campaigns based on format and flatten the array
  const filteredAndFlattenedCampaigns =
    isValidArrayWithData(shelvesCampaigns) &&
    shelvesCampaigns.flatMap(filterCampaignsByFormat)

  return filteredAndFlattenedCampaigns
}

export type TGetDataPromotion = {
  backgroundColor: string
  borderRadius?: string
  campaignId?: string
  color: string
  fontSize?: number
  fontWeight?: string
  headerLabel?: boolean
  height?: string
  icon?: keyof typeof Icons
  isMembership?: boolean
  isMobile?: boolean
  label: string
  lineHeight?: string
  padding?: string
  place?: string
  positionIcon?: 'left' | 'right'
  provider?: string
  width?: string
}

export const getDataPromotion = (
  dataPromotions,
  promotionId
): TGetDataPromotion | null => {
  let promotion = null

  if (dataPromotions) {
    const promotionTemp = dataPromotions?.find((dataPromotion) => {
      return promotionId == dataPromotion?.['campaignId']
    })

    if (promotionTemp) {
      promotion = {
        backgroundColor: promotionTemp?.['color'],
        color: promotionTemp?.['textColor'],
        label: promotionTemp?.['campaignName'],
        image: promotionTemp?.['campaignIcon']?.file?.url,
        campaignId: promotionId
      }
    }
  }

  return promotion
}
