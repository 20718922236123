import {
  IEntriesProps,
  ItemFields,
  useMembershipSavings,
  formatSubtotal,
  getContentfulData,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { useEffect, useState } from 'react'
import { MEMBERSHIP_BOX } from 'shared/utils/constants'

const MIN_AMOUNT = 20000

export interface IUseSavingsData {
  itemFromCF: ItemFields
  savings: string
  showSavings: boolean
  showSavingsToast: boolean
  handleSavingsToast: () => void
  refetchSavings: () => void
}

/**
 * Custom hook to calculate and manage savings data based on the cart information.
 *
 * @returns {IUseSavingsData} An object containing savings data, membership-specific content,
 *                            a flag to show/hide savings, and a function to refetch savings data.
 *
 * @example
 * import { useSavingsData } from './path/to/hook';
 *
 * const cartData = {
 *   subtotal: 150,
 *   membershipInfo: {
 *     membership: 'ACTIVE',
 *   },
 * };
 *
 * const { itemFromCF, refetchSavings, savings, showSavings, handleSavingsToast, showSavingsToast } = useSavingsData(cartData);
 *
 * console.log(savings); // e.g., '25'
 * console.log(showSavings); // true or false depending on the conditions
 * refetchSavings(); // Manually refetch savings data if needed
 */
export const useSavingsData = (): IUseSavingsData => {
  const [savings, setSavings] = useState('')
  const [showSavings, setShowSavings] = useState(false)
  const [CFData, setCFData] = useState<IEntriesProps>()
  const [showSavingsToast, setShowSavingsToast] = useState(true)

  const { data: cartData, isFetching: isFetchingCart } = useNewCartUnimarc()

  const {
    data: membershipSavings,
    isLoading: isLoadingMembershipSavings,
    refetch: refetchSavings,
    isFetching
  } = useMembershipSavings()

  const status = cartData?.membershipInfo?.membership ?? 'default'

  const handleToShowSavings = async (data: IEntriesProps, saving: string) => {
    const formatedSubTotal: number = formatSubtotal(cartData?.subtotal)
    const formatedSavings: number = formatSubtotal(saving)
    const formatMinFromCF: number = formatSubtotal(
      data?.items?.[0]?.fields?.url
    )

    if (
      data?.items?.[0]?.fields?.showbutton === false ||
      status === MEMBERSHIP_STATUS.ACTIVE ||
      status === MEMBERSHIP_STATUS.INACTIVE ||
      formatedSubTotal <= MIN_AMOUNT ||
      formatedSavings <= formatMinFromCF
    ) {
      setShowSavings(false)
    } else {
      setShowSavings(true)
    }
  }

  const handleGetFirstData = async () => {
    const removeMinus: string = membershipSavings?.savings.replace('-', '')
    const response = await getContentfulData({
      id_contentful: 'contentful-membership-box',
      options: {
        'sys.id': MEMBERSHIP_BOX[status]
      },
      reactQuery: {
        refetchInterval: 6000
      }
    })
    if (response) {
      setCFData(response)
      setSavings(removeMinus)
      handleToShowSavings(response, removeMinus)
    }
  }

  const handleRefetchSavings = () => {
    refetchSavings()
    handleToShowSavings(CFData, savings)
  }

  const handleSavingsToast = () => {
    setShowSavingsToast(false)
  }

  useEffect(() => {
    if (
      cartData &&
      !isLoadingMembershipSavings &&
      membershipSavings?.savings &&
      membershipSavings?.savings !== 'No savings'
    ) {
      handleGetFirstData()
    }

    if (
      !membershipSavings?.savings ||
      status === MEMBERSHIP_STATUS.ACTIVE ||
      membershipSavings?.savings === 'No savings'
    ) {
      setShowSavings(false)
    }
  }, [
    cartData?.subtotal,
    isLoadingMembershipSavings,
    membershipSavings,
    isFetching,
    isFetchingCart
  ])

  return {
    handleSavingsToast,
    itemFromCF: CFData?.items?.[0]?.fields,
    refetchSavings: handleRefetchSavings,
    savings,
    showSavings,
    showSavingsToast
  }
}
