import { ISelectedAddress } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter'

interface IAddressStyle {
  containerMargin: string
  minHeightContainer: string
  paddingContainer: string
  maxWidthContainer: string
  iconSize: string
}

/**
 * Retrieves address parameters and style based on the selected address.
 * @param {ISelectedAddress} selectedAddress - The selected address object.
 * @param {Object} objetSrcImagesIconAddress - Object containing image URLs for address icons.
 * @param {boolean|string} isMobile - Indicates if the device is mobile.
 * @param {boolean} [fromMyShipment] - Optional parameter indicating if the function is called from My Shipment.
 * @returns {Object} - An object containing address parameters and style.
 * @example
 * const params = getAddressParams(selectedAddress, objetSrcImagesIconAddress, isMobile, fromMyShipment);
 * Output:
 * {
 *   addressParams: {
 *     srcImageIconAddress: 'store-icon-url',
 *     typeOfAddress: 'Retiro en tienda',
 *     formatedAddress: ' Main St 123, Downtown, California.'
 *   },
 *   addressStyle: {
 *     containerMargin: '0',
 *     minHeightContainer: '30px',
 *     paddingContainer: '8px 16px',
 *     maxWidthContainer: '26px',
 *     iconSize: '16'
 *   }
 */
export const getAddressParams = (
  selectedAddress: ISelectedAddress,
  objetSrcImagesIconAddress: {
    imgStore: string
    imgByke: string
  },
  isMobile: boolean | string,
  fromMyShipment?: boolean
) => {
  const state = capitalizeFirstLetter(
    selectedAddress?.state.toLocaleLowerCase() ?? ''
  )

  const showComplement = () => {
    if (selectedAddress?.complement) {
      return `${selectedAddress?.complement},`
    }
    return ''
  }

  const addressParamsAll = {
    'pickup-in-point': {
      srcImageIconAddress: objetSrcImagesIconAddress.imgStore,
      typeOfAddress: 'Retiro en tienda',
      formatedAddress: ` ${selectedAddress?.street} ${selectedAddress?.number}, ${selectedAddress?.neighborhood}, ${state}.`
    },
    residential: {
      srcImageIconAddress: objetSrcImagesIconAddress.imgByke,
      typeOfAddress: 'Despacho a domicilio',
      formatedAddress: `${selectedAddress?.street} ${selectedAddress?.number}, ${showComplement()} ${selectedAddress?.neighborhood}, ${state}.`
    }
  }

  const addressStyle = (): IAddressStyle => {
    if (fromMyShipment) {
      return {
        containerMargin: '0',
        minHeightContainer: isMobile ? '30px' : '24px',
        paddingContainer: isMobile ? '8px 16px' : '20px 16px',
        maxWidthContainer: isMobile ? '26px' : '34px',
        iconSize: isMobile ? '16' : '24'
      }
    }
    return {
      containerMargin: '0 0 20px 0',
      minHeightContainer: isMobile ? '80px' : '119px',
      paddingContainer: isMobile ? '10px 16px' : '20px 16px',
      maxWidthContainer: '100%',
      iconSize: isMobile ? '16' : '29'
    }
  }

  return {
    addressParams: addressParamsAll[selectedAddress?.addressType],
    addressStyle: addressStyle()
  }
}
