/**
 * Capitalize the first letter of each word
 * @param inputString as string
 * @returns same string received with the first letter capitalized of each word
 */
export const capitalizeFirstLetter = (inputString: string): string => {
  const words: string[] = inputString.split(' ')
  const capitalizedWords: string[] = words.map((word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  // Join capitalized words in a new string
  const resultString: string = capitalizedWords.join(' ')

  return resultString
}
