import { Column, Icon, Link, Row } from '@smu-chile/pkg-unimarc-components'
import styles from './SubMenu.module.css'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

interface SubMenuProps {
  saveList?: () => void
  clearCart?: () => void
  disabledButtons?: boolean
}

export const SubMenu = ({
  clearCart,
  saveList,
  disabledButtons = false
}: SubMenuProps) => {
  const styleLink = disabledButtons ? styles.disabled : styles.link
  return (
    <Row justifyContent='center'>
      <Column
        customClassName={styles.borderRight}
        padding='0 12px 0 0'
        width={170}
      >
        <Row
          customClassName={styleLink}
          id={getBemId('saveAsList', 'container')}
          justifyContent='end'
        >
          <Link
            fontSize='md'
            href='#'
            label='Guardar como lista'
            onClick={saveList}
          />
          <Icon
            clickable='pointer'
            customSize={20}
            name='MyLists'
            strokeWidth='1.5'
          />
        </Row>
      </Column>

      <Column width={140}>
        <Row
          customClassName={styleLink}
          id={getBemId('emptyCart', 'container')}
          justifyContent='start'
          padding='0 0 0 12px'
        >
          <Link
            fontSize='md'
            href='#'
            label='Vaciar carro'
            onClick={clearCart}
          />
          <Icon
            clickable='pointer'
            customSize={20}
            name='Trash'
            strokeWidth='1'
          />
        </Row>
      </Column>
    </Row>
  )
}
