import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  eventClick,
  goToHome,
  useEvents,
  useMobile,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'
import { ModalOptionsMobile } from '../ModalOptionsMobile'
import { useRouter } from 'next/router'
import { HEADER_TITLE } from 'shared/utils/constants'

export interface HeadTotalCountProps {
  hideMenu?: boolean
  isLoading?: boolean
  title?: string
  handleOpenMyListsModal: () => void
}

export const HeadTotalCount = ({
  hideMenu = false,
  isLoading,
  title = HEADER_TITLE[0],
  handleOpenMyListsModal
}: HeadTotalCountProps): React.ReactElement => {
  const router = useRouter()
  const { trigger } = useTrigerEvent()
  const { isMobile } = useMobile()
  const [openMenu, setOpenMenu] = useState(false)
  const [productNote, setProductNote] = useState(false)
  const [productNotePosition, setProductNotePosition] = useState(0)

  const handleOpen = () => {
    setOpenMenu(false)
    trigger({ eventType: 'clearCart', data: { show: true } })
    eventClick({
      event: 'interacciones_carrito',
      eventAction: 'navegacion_compra',
      eventCategory: 'carrito',
      eventLabel: 'vaciar carro'
    })
  }

  const goBackLogic = () => {
    if (productNote) {
      trigger({ eventType: 'productNote', data: { show: false } })
      return
    }

    eventClick({ event: 'interacciones-carro', eventCategory: 'volver' })

    if (router.pathname === '/') {
      window.location.href = goToHome()
    } else {
      router.push('/')
    }
  }

  useEvents({
    eventType: 'productNote',
    callBack: ({ detail: { show } }) => {
      !productNote && setProductNotePosition(window.scrollY)
      setProductNote(show)
    }
  })

  useEffect(() => {
    if (!isMobile) return

    if (productNote) window.scrollTo(0, 0)
    if (!productNote) window.scrollTo(0, productNotePosition)
  }, [productNote])

  return (
    <SmallScreen>
      <Container
        backgroundColor={getGlobalStyle('--color-base-white')}
        border={`0.3px solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
        id={getBemId('headerCart', 'container')}
        maxHeight='54px'
        padding='15px -0px 15px 20px'
      >
        <Row justifyContent='between'>
          <Column maxWidth='-webkit-fill-available'>
            <Row style={{ justifyContent: 'space-between' }}>
              {isLoading ? (
                <Spacer.Vertical customSize={39} />
              ) : (
                <Column
                  alignItems='start'
                  id={getBemId('headerIcon', 'container')}
                  justifyContent='center'
                  maxWidth='40px'
                  onClick={() => {
                    goBackLogic()
                  }}
                  padding='4px 0px'
                >
                  <Icon
                    color={getGlobalStyle('--color-base-black')}
                    name='Back3'
                    sizes='sm'
                  />
                </Column>
              )}
              <Column
                id={getBemId('headerTitle', 'container')}
                justifyContent='center'
                maxWidth='max-content'
              >
                <Text
                  fontSize='lg'
                  fontWeight='semibold'
                  id={getBemId(
                    title === HEADER_TITLE[0] ? 'headerCart' : 'headerShipment',
                    'title'
                  )}
                >
                  {title}
                </Text>
              </Column>
              <Spacer.Vertical size={4} />
            </Row>
          </Column>
          {hideMenu || isLoading ? (
            <Spacer.Vertical size={64} />
          ) : (
            <Column
              alignItems='end'
              justifyContent='center'
              margin={isMobile ? '0 16px 0 0' : '0'}
              maxWidth='40px'
              minHeight='33.78px'
              onClick={() => {
                return setOpenMenu(!openMenu)
              }}
              position='relative'
            >
              {openMenu && (
                <Container
                  onClick={() => {
                    return setOpenMenu(!openMenu)
                  }}
                  style={{
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    left: 'inherit',
                    top: 'inherit',
                    backgroundColor: getGlobalStyle('--color-neutral-black'),
                    opacity: '40%',
                    zIndex: getGlobalStyle('--z-index-999')
                  }}
                />
              )}
              <Icon
                color={getGlobalStyle('--color-alvi-neutral-black')}
                name='MenuRound'
                sizes='sm'
              />
              {openMenu && (
                <ModalOptionsMobile
                  handleOpenMyListsModal={handleOpenMyListsModal}
                  onClearCart={handleOpen}
                />
              )}
            </Column>
          )}
        </Row>
      </Container>
    </SmallScreen>
  )
}
