import { Text, Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import NameStyles from './Name.module.css'

export interface NameProps {
  isMobile?: boolean
  measurement?: string
  name: string
  quantity: number
}

export const Name = ({
  isMobile = false,
  measurement,
  name,
  quantity
}: NameProps): React.ReactElement<NameProps> => {
  return (
    <Column customWidth='100'>
      <Text
        customClassName={NameStyles.productName__brand}
        fontSize={isMobile ? 'sm' : 'lg'}
        id={getBemId('restockedModal', 'product', 'name')}
        lineClamp={2}
      >
        {name}
      </Text>
      {!isMobile && <Spacer.Horizontal customSize={4} />}
      <Text
        color='gray'
        fontSize={isMobile ? 'xs' : 'md'}
        fontWeight='medium'
        id={getBemId('restockedModal', 'product', 'quantity')}
      >
        {quantity} {measurement}
      </Text>
    </Column>
  )
}
