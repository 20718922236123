import {
  Container,
  NotificationBubble,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { ToastBody } from './ToastBody'

export interface MembershipToastProps {
  isLoadingByEvent?: boolean
  item: ItemFields
  savings: string
  showingUnipay?: boolean
  handleSavingsToast: () => void
}

export const MembershipToast = ({
  isLoadingByEvent,
  item,
  savings,
  showingUnipay,
  handleSavingsToast
}: MembershipToastProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <>
          <Tooltip
            alignAllItems='center'
            background={getGlobalStyle('--color-secondary-blue-bg')}
            borderRadius={getGlobalStyle('--border-radius-md')}
            boxShadow='none'
            closeColor={getGlobalStyle('--color-neutral-black')}
            closeIcon={false}
            display='flex'
            height='60px'
            maxWidth='375px'
            padding='12px 16px'
            position='sticky'
            positionContainer='sticky'
            textComponent={
              <ToastBody
                handleSavingsToast={handleSavingsToast}
                item={item}
                savings={savings}
              />
            }
            type='bottom'
            width='375px'
          />
          {isLoadingByEvent && (
            <Container
              style={{
                background: 'rgba(255,255,255, 0.4)',
                borderRadius: getGlobalStyle('--border-radius-md'),
                height: '60px',
                maxWidth: '375px',
                position: 'absolute',
                top: '0'
              }}
            />
          )}
        </>
      </BigScreen>
      <SmallScreen>
        <>
          <NotificationBubble
            background='--color-secondary-blue-bg'
            customPadding='12px 16px'
            customTop={showingUnipay ? '-98px' : '-68px'}
            height='85px'
            isSticky={true}
            minWidth='100%'
          >
            <ToastBody
              handleSavingsToast={handleSavingsToast}
              item={item}
              savings={savings}
            />
          </NotificationBubble>
          {isLoadingByEvent && (
            <Container
              style={{
                background: 'rgba(255,255,255, 0.4)',
                borderRadius: '12px 12px 0px 0px',
                height: '85px',
                minWidth: '100%',
                position: 'absolute',
                top: showingUnipay ? '-98px' : '-68px'
              }}
            />
          )}
        </>
      </SmallScreen>
    </>
  )
}
