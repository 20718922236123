import {
  Column,
  Icon,
  Modal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ModalOptionsMobile.module.css'

interface ModalOptionsMobilePorps {
  handleOpenMyListsModal: () => void
  onClearCart?: () => void
}

export const ModalOptionsMobile = ({
  handleOpenMyListsModal,
  onClearCart
}: ModalOptionsMobilePorps) => {
  return (
    <Modal
      borderRadius={`${getGlobalStyle('--border-radius-md')} ${getGlobalStyle(
        '--border-radius-md'
      )} 0px 0px `}
      className={styles.clearcart__mobile}
      height='231px'
      hiddenX
      hideOverlay
      isOpen
      marginFullScreen='0px'
      maxHeight='231px'
      maxWidth='100%'
      minHeight='231px'
      minHeightFullScreen='375px'
      padding='0'
      site='unimarc'
    >
      <Column>
        <Row
          alignItems='center'
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          customHeight='61px'
          justifyContent='between'
          padding='0 26px'
        >
          <span />
          <Text
            fontSize='lg'
            fontWeight='semibold'
            textAlign='center'
          >
            ¿Qué te gustaría hacer?
          </Text>
          <Icon
            clickable='pointer'
            color={getGlobalStyle('--color-neutral-black')}
            customSize={20}
            name='Close'
          />
        </Row>
        <Column
          gap='26px'
          padding='36px 26px 0'
        >
          <Row
            alignItems='center'
            id={getBemId('saveAsList', 'container')}
            justifyContent='between'
            onClick={handleOpenMyListsModal}
          >
            <Text
              fontSize='lg'
              fontWeight='regular'
            >
              Guardar como lista
            </Text>
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-neutral-black')}
              customSize={24}
              name='MyLists'
              strokeWidth='1.5'
            />
          </Row>
          <Row
            alignItems='center'
            id={getBemId('emptyCart', 'container')}
            justifyContent='between'
            onClick={() => {
              return onClearCart()
            }}
          >
            <Text
              fontSize='lg'
              fontWeight='regular'
            >
              Vaciar carro
            </Text>
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-neutral-black')}
              customSize={24}
              name='Trash'
              strokeWidth='1'
            />
          </Row>
        </Column>
      </Column>
    </Modal>
  )
}
