import { useState } from 'react'
import classNames from 'classnames'
import { Container, Column, Tabs } from '@smu-chile/pkg-unimarc-components'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { ModalBodyShelves } from '../ModalBodyShelves'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { TooltipLimitCart } from 'components/Body/ProductsTotalizers/TooltipWrapper/TooltipLimitCart'
import styles from '../MyUsualsAndFavoritesWrapper.module.css'

const TAB_TITLES = {
  myUsuals: 'Lo que más compras 🤩',
  favorites: 'Mis favoritos ❤️'
}

interface ModalBodyProps {
  favorites: IUsualsAndFavoritesData
  isLoadingMyUsualsAndFavorites: boolean
  isLoadingOrderForm: boolean
  isMobile?: boolean
  myUsuals: IUsualsAndFavoritesData
  handleChancePage: () => void
  scrollToTopOnContent: () => void
}

export const ModalBody = ({
  favorites,
  isLoadingMyUsualsAndFavorites,
  isLoadingOrderForm,
  isMobile,
  myUsuals,
  handleChancePage,
  scrollToTopOnContent
}: ModalBodyProps): React.ReactElement => {
  const [showToast, setShowToast] = useState(false)

  const getTitles = (): string[] => {
    const titles: string[] = []

    const addTitleIfValid = (
      data: typeof myUsuals | typeof favorites,
      title: string
    ) => {
      if (isValidArrayWithData(data?.products)) {
        titles.push(title)
      } else {
        // Return true if we need to add an empty string later
        return true
      }
      return false
    }

    // Track if we need to add an empty string
    let needEmptyString = false

    // Add titles for options with products
    if (addTitleIfValid(myUsuals, TAB_TITLES.myUsuals)) {
      needEmptyString = true
    }
    if (addTitleIfValid(favorites, TAB_TITLES.favorites)) {
      needEmptyString = true
    }

    // Add empty string at the second position if needed
    if (needEmptyString) {
      titles.splice(1, 0, '') // Add empty string at index 1 (second position)
    }

    return titles
  }

  const handleShowToast = (value = false) => {
    setShowToast(value)
  }

  const getTabs = (): Array<React.ReactChild> => {
    const tabs: Array<React.ReactChild> = []

    const addTabIfValid = (data: typeof myUsuals | typeof favorites) => {
      if (isValidArrayWithData(data?.products)) {
        tabs.push(
          <ModalBodyShelves
            currentPage={data?.currentPage}
            data={data}
            handleChancePage={handleChancePage}
            handleShowToast={handleShowToast}
            isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
            isLoadingOrderForm={isLoadingOrderForm}
            isMobile={isMobile}
            totalPages={data?.totalPages}
          />
        )
      }
    }

    addTabIfValid(myUsuals)
    addTabIfValid(favorites)

    return tabs
  }

  return (
    <Container
      isWrap
      justifyContent='center'
      padding='0 16px'
      position='relative'
    >
      {showToast && (
        <div
          style={{
            position: 'fixed',
            zIndex: getGlobalStyle('--z-index-10'),
            top: isMobile ? '65px' : '85px'
          }}
        >
          <TooltipLimitCart
            customPadding='12px 12px 12px 8px'
            message='Haz alcanzado el máximo de productos que puedes llevar.'
            onClose={handleShowToast}
          />
        </div>
      )}
      <Column
        id={getBemId('myUsualsAndFavoritesModal', 'tabs')}
        minHeight='calc(100vh - 140px)'
      >
        <Tabs
          customBorderWidth={true}
          customTabClassName={classNames(styles.customTab, {
            [styles.oneTab]: Boolean(getTabs().length === 1)
          })}
          disableWithOneOption={Boolean(getTabs().length === 1)}
          scrollToTopOnContent={scrollToTopOnContent}
          tabs={getTabs()}
          titles={getTitles()}
          titlesAccentFontWeight='semibold'
          titlesFontWeight='regular'
        />
      </Column>
    </Container>
  )
}
