import { useEffect } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { globalConfig } from '../helpers/tooltipGlobalConfig'

export interface TooltipLimitCartProps {
  customPadding?: string
  message?: string
  onClose: () => void
}

export const TooltipLimitCart = ({
  customPadding,
  message = 'Haz alcanzado el máximo de productos.',
  onClose
}: TooltipLimitCartProps): React.ReactElement => {
  const { isMobile } = useMobile()

  const {
    position,
    topMobile,
    width,
    rightMobile,
    leftMobile,
    sizeAlertModalIcon,
    paddingModal
  } = globalConfig(isMobile)

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 4000)
  })

  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-info-bg')}
      bottom=''
      boxShadow='0px 6px 10px rgba(27, 33, 56, 0.15)'
      closeColor={getGlobalStyle('--color-neutral-black')}
      closeIcon
      display='flex'
      height='56px'
      left={leftMobile}
      maxWidth='377px'
      onClick={onClose}
      padding={customPadding ?? paddingModal}
      position={position}
      positionContainer='sticky'
      right={rightMobile}
      sizeCloseIcon={16}
      textComponent={
        <Container maxHeight='60px'>
          <Row alignItems='center'>
            <Column maxWidth='max-content'>
              <Icon
                color={getGlobalStyle('--color-feedback-info')}
                customSize={sizeAlertModalIcon}
                name='AlertModal'
              />
            </Column>
            <Spacer size={8} />
            <Column
              margin='0 10px 0 0'
              maxWidth='max-content'
            >
              <Text
                color='neutral-black'
                fontSize='md'
                fontWeight='medium'
                lineHeight='15px'
              >
                {message}
              </Text>
            </Column>
          </Row>
        </Container>
      }
      top={topMobile}
      type='bottom'
      width={width}
    />
  )
}
