import classNames from 'classnames'
import {
  DesktopModalWrapper,
  Container,
  Text,
  Column,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IRestockedProducts } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostStockAvailability'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'
import { ProductShelf } from '../ProductShelf'
import { RestockedModalButton } from '../RestockedModalButton'
import wrapperStyles from '../../MyUsualsAndFavoritesWrapper/MyUsualsAndFavoritesWrapper.module.css'
import styles from '../RestockedModal.module.css'

interface RestockedModalDesktopProps {
  restockedProducts: IRestockedProducts
  handleClose: () => void
}

export const RestockedModalDesktop = ({
  restockedProducts,
  handleClose
}: RestockedModalDesktopProps) => {
  const modal = document.getElementById('restockedModal__container')

  const onClose = async () => {
    if (modal) {
      modal.classList.remove(wrapperStyles.openModal)
      modal.classList.add(wrapperStyles.closeModal)
      await sleep(200)
    }
    handleClose()
  }

  useEffect(() => {
    if (modal) {
      modal.classList.add(wrapperStyles.openModal)
      modal.classList.remove(wrapperStyles.closeModal)
    }
  }, [modal])

  return (
    <DesktopModalWrapper
      blockId={getBemId('restockedModal', 'header')}
      bodyChildrenProps={{
        maxHeight: 'none',
        overflow: 'auto',
        padding: '0'
      }}
      borderEdge='none'
      footerChildrenProps={{
        boxShadow: '0',
        default: false,
        children: (
          <Container
            justifyContent='center'
            padding='16px'
          >
            <RestockedModalButton handleClose={handleClose} />
          </Container>
        ),
        height: 72,
        padding: '0',
        bottom: '0'
      }}
      headerCloseIcon={{
        name: 'CloseThin',
        customSize: 20,
        default: true
      }}
      headerContainerProps={{
        minHeight: '68px'
      }}
      headerCustomClass={wrapperStyles.customHeader}
      headerTitle='Están de vuelta'
      headerTitleProps={{
        fontWeight: 'semibold',
        default: true
      }}
      modalConfigsProps={{
        containerId: getBemId('restockedModal', 'container'),
        customContainerClassName: classNames(
          wrapperStyles.modalContainer,
          styles.modalStyles
        ),
        isOpen: true,
        minHeight: '100vh',
        maxWidth: '467px',
        isWindowBlocked: true,
        toggle: onClose,
        toggleOutside: onClose
      }}
      styleProps={{
        borderRadius: getGlobalStyle('--border-radius-none'),
        customHeightModalContent: '100%'
      }}
    >
      <Column
        minHeight='calc(100vh - 160px)'
        padding='16px'
      >
        <Column
          id={getBemId('restockedModal', 'title')}
          justifyContent='center'
        >
          <Text textAlign='center'>
            Estos productos volvieron a estar disponibles, los agregaremos a tu
            carro.
          </Text>
          <Spacer.Horizontal customSize={20} />
        </Column>
        {restockedProducts.map(
          ({ id, name, image, measurementUnit, quantity }) => {
            return (
              <Column
                key={id}
                padding='6px 16px'
              >
                <ProductShelf
                  img={image}
                  measurement={measurementUnit}
                  name={name}
                  quantity={quantity}
                />
                <Spacer.Horizontal customSize={10} />
              </Column>
            )
          }
        )}
      </Column>
    </DesktopModalWrapper>
  )
}
