import {
  Container,
  Row,
  Column,
  Spacer,
  Button,
  Icon,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { MembershipToastProps } from '..'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../MembershipToast.module.css'

export const ToastBody = ({
  item,
  savings,
  handleSavingsToast
}: MembershipToastProps): React.ReactElement => {
  const handleOpenMembershipModal = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'carro' }
    })
  }
  return (
    <Container>
      <Row
        alignItems='center'
        clickable='pointer'
        onClick={handleOpenMembershipModal}
      >
        <Column clickable='pointer'>
          <Row
            clickable='pointer'
            maxWidth='max-content'
          >
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-membership')}
              fontSize='sm'
              fontWeight='medium'
              type='span'
            >
              {item?.subtitulo}&nbsp;
            </Text>
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-membership')}
              fontSize='sm'
              fontWeight='semibold'
              type='span'
            >
              {savings}&nbsp;
            </Text>
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-membership')}
              fontSize='sm'
              fontWeight='medium'
              type='span'
            >
              {item?.subtitulo2}
            </Text>
          </Row>
          <Spacer.Horizontal customSize={4} />
          <Text
            customColor={getGlobalStyle('--color-primary-membership')}
            fontSize='sm'
          >
            {item?.buttonText}
          </Text>
        </Column>
        <Column maxWidth='max-content'>
          <Button
            background={getGlobalStyle('--color-primary-membership')}
            borderRadius={getGlobalStyle('--border-radius-xl')}
            customClassName={styles.customButton}
            height='23px'
            label={item?.label}
            maxWidth='94px'
            minWidth='94px'
            size='xs'
          />
        </Column>
      </Row>
      <Spacer.Vertical customSize={16} />
      <Column
        clickable='pointer'
        maxWidth='max-content'
        onClick={handleSavingsToast}
      >
        <Icon
          clickable='pointer'
          color={getGlobalStyle('--color-primary-membership')}
          customSize={14}
          name='CloseThin'
        />
      </Column>
    </Container>
  )
}
