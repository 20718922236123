import { Button } from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'

export const RestockedModalButton = ({
  handleClose
}: {
  handleClose: () => void
}) => {
  return (
    <Button
      borderRadius={getGlobalStyle('--border-radius-xl')}
      fontSize='lg'
      fontWeight={getGlobalStyle('--font-weight-medium')}
      id={getBemId('restockedModal', 'button')}
      label='Entendido'
      minWidth='100%'
      onClick={handleClose}
      size='lg'
      type='primary-gray'
    />
  )
}
