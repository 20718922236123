import { Button } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ModalButtonProps {
  isLoadingOrderForm: boolean
  minWidth?: string
  handleOnClick: () => void
}

export const ModalButton = ({
  isLoadingOrderForm,
  minWidth,
  handleOnClick
}: ModalButtonProps): React.ReactElement => {
  return (
    <Button
      borderRadius={getGlobalStyle('--border-radius-xl')}
      fontSize='lg'
      fontWeight={getGlobalStyle('--font-weight-medium')}
      id={getBemId('myUsualsAndFavoritesModal', 'button')}
      isLoading={isLoadingOrderForm}
      label='Continuar'
      minWidth={minWidth}
      onClick={handleOnClick}
      pointLoaderBigger={true}
      size='lg'
      type='primary-gray'
    />
  )
}
