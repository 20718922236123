import {
  Row,
  Icon,
  Spacer,
  Text,
  IconNames
} from '@smu-chile/pkg-unimarc-components'

interface GrayLinkProps {
  colorIcon: string
  colorText: string
  customIconSize: number
  iconName: IconNames
  id: string
  isMobile?: boolean
  label: string
  onClick?: () => void
}

export const GrayLink = ({
  colorIcon,
  colorText = 'gray',
  customIconSize,
  iconName,
  id,
  isMobile,
  label,
  onClick
}: GrayLinkProps) => {
  return (
    <Row
      alignItems='center'
      clickable='pointer'
      id={id}
      maxWidth='max-content'
      onClick={onClick}
      position='relative'
    >
      <Icon
        clickable='pointer'
        color={colorIcon}
        customSize={customIconSize}
        name={iconName}
        sizes='xs'
      />
      <Spacer.Vertical customSize={3} />
      <Text
        clickable='pointer'
        customColor={colorText}
        fontSize={isMobile ? 'xs' : 'sm'}
      >
        {label}
      </Text>
    </Row>
  )
}
