import {
  Column,
  Container,
  Icon,
  Row,
  Tooltip,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { toCapitalize } from '@smu-chile/pkg-unimarc-components/utils'
import { useEffect } from 'react'

export interface ITooltipAddOrderToList {
  createListButton: boolean
  isMobile: boolean
  selectedListName: string
  tooltipPrefixText?: string
  onClose: () => void
  setNotificationAddToList: (type: boolean) => void
}

export const TooltipAddOrderToList = ({
  createListButton,
  isMobile,
  selectedListName,
  tooltipPrefixText = 'Tu carro',
  onClose,
  setNotificationAddToList
}: ITooltipAddOrderToList) => {
  const newList = createListButton ? 'nueva ' : ''
  const textTooltip = `${tooltipPrefixText} se agregó a la ${newList} lista `
  const width = isMobile ? '92%' : '377px'
  useEffect(() => {
    setTimeout(() => {
      setNotificationAddToList(false)
    }, 6000)
  }, [])

  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-success-bg')}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-icons-black')}
      display='flex'
      height='56px'
      iconMargin='0 4px 0 0'
      id={getBemId('tooltipAddToList', 'container')}
      left={isMobile ? '0' : ''}
      maxWidth='377px'
      onClick={onClose}
      padding='12px 12px 12px 8px'
      position='relative'
      right='0'
      textComponent={
        <Container
          alignItems='center'
          minHeight='100%'
        >
          <Row
            alignItems='center'
            gap='8px'
          >
            <Column maxWidth='max-content'>
              <Icon
                color={getGlobalStyle('--color-feedback-success-light')}
                customSize={20}
                name='CheckCircle'
              />
            </Column>
            <Column margin='0 8px 0 0'>
              <Text
                color='black'
                display='inline-block'
                fontSize='md'
                fontWeight='medium'
                id={getBemId('tooltipAddToList', 'text')}
                lineHeight='15px'
              >
                {textTooltip}
                <Text
                  color='black'
                  display='inline-block'
                  fontSize='md'
                  fontWeight='medium'
                  type='span'
                >
                  &quot;
                  <Text
                    color='black'
                    display='inline-block'
                    fontSize='md'
                    fontWeight='semibold'
                    truncate={22}
                    type='span'
                  >
                    {toCapitalize(selectedListName)}
                  </Text>
                  &quot;
                </Text>
              </Text>
            </Column>
            <Column
              clickable='pointer'
              id={getBemId('tooltipAddToList', 'closeIcon')}
              maxWidth='15px'
              onClick={() => {
                return setNotificationAddToList(false)
              }}
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-neutral-gray-dark')}
                customSize={14}
                name='Close'
              />
            </Column>
          </Row>
        </Container>
      }
      top={isMobile ? '54px' : ''}
      type='bottom'
      width={width}
    />
  )
}
