import React, { useEffect, useState } from 'react'
import { useEvents } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  ButtonStatus,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface ButtonMakeOrderProps {
  cartData?: ICart
  isLoading?: boolean
  isLoadingByEvent?: boolean
  handleOpenMyUsualsAndFavorites?: () => void
}

const ButtonMakeOrder = ({
  cartData,
  isLoading = false,
  isLoadingByEvent = false,
  handleOpenMyUsualsAndFavorites
}: ButtonMakeOrderProps): React.ReactElement => {
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('initial')

  const addressValidator =
    !cartData?.address || cartData?.address?.country !== 'CHL'
  const minValue =
    Number(
      typeof cartData?.minimumPurchase === 'string'
        ? parseFloat(cartData?.minimumPurchase.replace(/[$.]/g, ''))
        : cartData?.minimumPurchase
    ) ?? 0

  const subtotal =
    Number(
      typeof cartData?.minimumPurchase === 'string'
        ? parseFloat(cartData?.subtotal.replace(/[$.]/g, ''))
        : cartData?.subtotal
    ) ?? 0
  const hasNotMinValue = minValue !== 0 && Number(minValue) > Number(subtotal)

  const handleOnClick = async () => {
    if (hasNotMinValue) {
      return
    }

    // open my usuals and favorites modal
    handleOpenMyUsualsAndFavorites()
  }

  useEvents({
    eventType: 'addressChanged',
    callBack: ({ detail: { addressChanged } }) => {
      if (addressChanged) setButtonStatus('disabled')
    }
  })

  useEffect(() => {
    if (isLoadingByEvent) {
      setButtonStatus('loading')
    } else {
      if (hasNotMinValue || addressValidator) {
        setButtonStatus('disabled')
      } else {
        setButtonStatus('initial')
      }
    }
  }, [isLoadingByEvent, cartData])

  return (
    <Container isWrap>
      <Row justifyContent='center'>
        <Button
          actionType='button'
          borderRadius={getGlobalStyle('--border-radius-xl')}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height='40px'
          id='GA__button--goMyShipments'
          isLoading={isLoading}
          label='Continuar con el envío'
          onClick={handleOnClick}
          pointLoaderBigger={true}
          size='lg'
          status={buttonStatus}
          type='primary-gray'
          width='100%'
        />
      </Row>
    </Container>
  )
}

export default ButtonMakeOrder
