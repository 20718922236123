import { useEffect, useState } from 'react'
import {
  Container,
  Column,
  ModalBottomSheet,
  AddToListModal
} from '@smu-chile/pkg-unimarc-components'
import { BodyTitle } from './BodyTitle'
import { ProductsTotalizers } from './ProductsTotalizers'
import { EmptyCart } from './EmptyCart'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  currentOrderformInfoTag,
  isValidArrayWithData,
  postStockAvailability,
  updateCurierName,
  updateTagOrderForm,
  useClearOrderFormNewApi,
  useContentful,
  useDeleteProductsIssues,
  useEvents,
  useMobile,
  useNewCartUnimarc,
  useOrderForm,
  useQueryClient,
  useSimulationsForPayments
} from '@smu-chile/pkg-unimarc-hooks'
import { ModalMessageItem } from 'components/ModalMessage'
import { ContentModalCleanCart } from './BodyTitle/ContentModalCleanCart'
import { ADDRESS_ICONS, IMG_CLEAN_CART } from './helper/constants'
import { useAddProductToList } from 'shared/utils/hooks'
import { CartFailed } from './CartFailed'
import { MyUsualsAndFavoritesWrapper } from 'components/MyUsualsAndFavoritesWrapper'
import { useMyUsualsAndFavorites } from 'shared/hooks/useMyUsualsAndFavorites'
import {
  IRestockedProducts,
  IStockAvailabilityRequest,
  IStockAvailabilityResponse
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostStockAvailability'
import { RestockedModal } from 'components/RestockedModal'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { UNIPAY_ID } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import stylesBody from './Body.module.css'

let analyticsOneLoad = false
let saveCourierNameOneLoad = false

export const Body = (): React.ReactElement => {
  const site = 'Unimarc'
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [isLoadingByEvent, setIsLoadingByEvent] = useState(false)
  const [productNote, setProductNote] = useState({
    open: false,
    item: {} as ModalMessageItem
  })
  const [openMyUsuals, setOpenMyUsuals] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [restockedProducts, setRestockedProducts] =
    useState<IRestockedProducts>()
  const { data, isLoading: isLoadingOrderForm } = useOrderForm()
  const cartData = data?.data
  const { mutate } = useClearOrderFormNewApi()
  const {
    data: newCart,
    isLoading: isLoadingCart,
    isFetching: isFetchingCart,
    refetch: refetchCard
  } = useNewCartUnimarc()
  const { handleDeleteProducts } = useDeleteProductsIssues()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const { refetch: refetchSimulation } = useSimulationsForPayments({
    payments: [UNIPAY_ID]
  })

  const [openCollapse, setOpenCollapse] = useState(true)
  const [openCollapseIssues, setOpenCollapseIssues] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseModal = () => {
    setOpenMyUsuals(false)
  }

  const imgGarlic = useContentful({
    id_contentful: 'contentful-image-clean-cart-garlic',
    options: {
      'sys.id': IMG_CLEAN_CART.imgGarlic
    },
    type: 'assets'
  })

  const imgOnion = useContentful({
    id_contentful: 'contentful-image-clean-cart-onion',
    options: {
      'sys.id': IMG_CLEAN_CART.imgOnion
    },
    type: 'assets'
  })

  const imgDonuts = useContentful({
    id_contentful: 'contentful-image-cart-failed',
    options: {
      'sys.id': IMG_CLEAN_CART.imgDonuts
    },
    type: 'assets'
  })

  const { data: dataCFImgStore } = useContentful({
    id_contentful: 'contentful-icon-address-store',
    options: {
      'sys.id': ADDRESS_ICONS.imgStore
    },
    type: 'assets'
  })

  const { data: dataCFImgByke } = useContentful({
    id_contentful: 'contentful-icon-address-byke',
    options: {
      'sys.id': ADDRESS_ICONS.imgByke
    },
    type: 'assets'
  })

  let garlicImage: string =
    imgGarlic?.data?.['items'][0]?.fields?.file?.url || ''
  let onionImage: string = imgOnion?.data?.['items'][0]?.fields?.file?.url || ''
  let donutsImage: string =
    imgDonuts?.data?.['items'][0]?.fields?.file?.url || ''

  garlicImage = `https:${garlicImage}`
  onionImage = `https:${onionImage}`
  donutsImage = `https:${donutsImage}`

  const srcImageOrderStore =
    dataCFImgStore?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderByke = dataCFImgByke?.['items'][0]?.fields?.file?.url || ''

  const objetSrcImagesIconAddress = {
    imgStore: srcImageOrderStore ?? '',
    imgByke: srcImageOrderByke ?? ''
  }

  useEvents({
    eventType: 'myUsuals',
    callBack: ({ detail: { show } }) => {
      setOpenMyUsuals(show)
    }
  })

  useEvents({
    eventType: 'clearCart',
    callBack: ({ detail: { show, error } }) => {
      setOpen(show)
      if (!show) setIsLoadingBtn(false)
      if (!error) refetchCard()
    }
  })

  useEvents({
    eventType: 'productNote',
    callBack: ({ detail: { show, item } }) => {
      setProductNote({
        ...productNote,
        item,
        open: show
      })
    }
  })

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading } }) => {
      setIsLoadingByEvent(loading)
    }
  })

  const {
    createListButton,
    emptyOrangeImage,
    listData,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  } = useAddProductToList(newCart)

  const {
    handleChancePage,
    handleOpenMyUsualsAndFavorites,
    isLoading: isLoadingMyUsualsAndFavorites,
    myUsualsAndFavoritesData
  } = useMyUsualsAndFavorites(newCart, isFetchingCart, openMyUsuals)

  const handleEmptyCard = () => {
    setIsLoadingBtn(true)
    mutate()
    // validate if productsIssues has items to delete them
    if (isValidArrayWithData(newCart?.productsIssues)) {
      const itemsToDelete = newCart?.productsIssues.map((issue) => {
        return issue.orderItem.id
      })
      handleDeleteProducts({ productsIssues: itemsToDelete })
    }
  }

  const handleCloseRestockedModal = () => {
    setRestockedProducts([])
  }

  useEffect(() => {
    if (!cartData) return

    updateTagOrderForm({ orderForm: cartData || { noSession: true } })

    if (
      data?.data?.selectedAddresses &&
      !isLoadingOrderForm &&
      !saveCourierNameOneLoad
    ) {
      updateCurierName({
        selectedAddresses: data?.data?.selectedAddresses,
        salesChannel: data?.data?.salesChannel
      })
      saveCourierNameOneLoad = true
    }
  }, [data])

  useEffect(() => {
    if (cartData && cartData?.orderFormId && analyticsOneLoad === false) {
      currentOrderformInfoTag({
        event: 'view_cart',
        site
      })
      analyticsOneLoad = true
    }
  }, [cartData])

  // look for products that could back again to be available
  useEffect(() => {
    const postStock = async (data: IStockAvailabilityRequest) => {
      const response = (await postStockAvailability(
        data
      )) as IStockAvailabilityResponse
      if (isValidArrayWithData(response?.restocked)) {
        queryClient.setQueryData<ICart>('/cart', response?.cart)
        refetchSimulation()
        setRestockedProducts(response?.restocked)
        setOpenCollapse(true)
      }
    }

    if (
      newCart &&
      !isLoadingCart &&
      !isFetchingCart &&
      isValidArrayWithData(newCart?.productsIssues)
    ) {
      const items = newCart?.productsIssues.map((product) => {
        return { ...product?.orderItem }
      })
      const data = { items, geoCoordinates: newCart?.address?.geoCoordinates }
      postStock(data)
    }
  }, [newCart, isFetchingCart])

  if (newCart?.error) {
    return <CartFailed donutsImage={donutsImage} />
  }

  if (
    !isLoadingCart &&
    !isValidArrayWithData(newCart?.products) &&
    !isValidArrayWithData(newCart?.productsIssues)
  ) {
    return <EmptyCart onionImage={onionImage} />
  }

  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-base-white')}
      customClassName={stylesBody.customHeight}
      id='testthis'
      justifyContent='center'
      tagName='main'
    >
      {open && (
        <ModalBottomSheet
          id={getBemId('emptyCartModal', 'container')}
          isLoadingBtn={isLoadingBtn}
          isMobile={isMobile}
          isOpen={true}
          minHeightModal='457px'
          minHeightModalMobile='576px'
          onClose={handleClose}
          primaryBtnLabel='Sí, vaciar'
          primaryCTA={() => {
            return handleEmptyCard()
          }}
          secondaryBtnLabel='No, gracias'
          secondaryCTA={handleClose}
          widthBtn={isMobile ? '343px' : '408px'}
        >
          <ContentModalCleanCart
            garlicImage={garlicImage}
            isMobile={isMobile}
          />
        </ModalBottomSheet>
      )}

      {openMyUsuals && (
        <MyUsualsAndFavoritesWrapper
          favorites={myUsualsAndFavoritesData?.favorites}
          handleChancePage={handleChancePage}
          handleClose={handleCloseModal}
          isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
          isLoadingOrderForm={isLoadingByEvent}
          myUsuals={myUsualsAndFavoritesData?.myUsuals}
          orderformData={data?.data}
        />
      )}

      {isValidArrayWithData(restockedProducts) && (
        <RestockedModal
          handleClose={handleCloseRestockedModal}
          restockedProducts={restockedProducts}
        />
      )}

      <AddToListModal
        createListButton={createListButton}
        emptyOrangeImage={emptyOrangeImage}
        handleAddProductsToList={handleAddProductsToList}
        handleCreateNewList={handleCreateNewList}
        innerWidth={innerWidth}
        isMobile={isMobile}
        listData={listData}
        modalTitle='Guardar como lista'
        openModalCreateList={openModalCreateList}
        setCreateListButton={setCreateListButton}
        setOpenModalCreateList={setOpenModalCreateList}
      />

      <BigScreen>
        <Container
          customWidth='90'
          isWrap
          maxWidth='1160px'
          tagName='section'
        >
          <ProductsTotalizers
            cartData={newCart}
            createListButton={createListButton}
            handleOnClosed={handleOnClosed}
            handleOpenMyListsModal={handleOpenMyListsModal}
            handleOpenMyUsualsAndFavorites={handleOpenMyUsualsAndFavorites}
            isFetchingCart={isFetchingCart}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingCart={isLoadingCart}
            isLoadingOrderForm={isLoadingOrderForm}
            notificationAddToList={notificationAddToList}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            openCollapseIssues={openCollapseIssues}
            openMyUsuals={openMyUsuals}
            selectedListName={selectedListName}
            setNotificationAddToList={setNotificationAddToList}
            setOpenCollapse={setOpenCollapse}
            setOpenCollapseIssues={setOpenCollapseIssues}
          />
        </Container>
      </BigScreen>

      <SmallScreen>
        <Column
          isWrap
          padding='0'
        >
          <BodyTitle
            handleOpenMyListsModal={handleOpenMyListsModal}
            isLoading={isLoadingByEvent || isLoadingCart || isLoadingOrderForm}
          />
          <ProductsTotalizers
            cartData={newCart}
            createListButton={createListButton}
            handleOnClosed={handleOnClosed}
            handleOpenMyListsModal={handleOpenMyListsModal}
            handleOpenMyUsualsAndFavorites={handleOpenMyUsualsAndFavorites}
            isFetchingCart={isFetchingCart}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingCart={isLoadingCart}
            isLoadingOrderForm={isLoadingOrderForm}
            notificationAddToList={notificationAddToList}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            openCollapseIssues={openCollapseIssues}
            openMyUsuals={openMyUsuals}
            selectedListName={selectedListName}
            setNotificationAddToList={setNotificationAddToList}
            setOpenCollapse={setOpenCollapse}
            setOpenCollapseIssues={setOpenCollapseIssues}
          />
        </Column>
      </SmallScreen>
    </Container>
  )
}
