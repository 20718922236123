import {
  Button,
  Column,
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { BodyTitle } from '../BodyTitle'
import styles from '../Body.module.css'

export const CartFailed = ({
  donutsImage
}: {
  donutsImage?: string
}): React.ReactElement => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const redirectToHome = () => {
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-base-white')}
      customClassName={styles.customHeight}
      customHeight={isMobile ? '100vh' : '100%'}
      customWidth='100%'
      isWrap={true}
      justifyContent='center'
      margin='20px 0 0 0'
      tagName='section'
    >
      <Row
        alignItems='center'
        customWidth='90'
        isWrap
        justifyContent='center'
        maxWidth='1440px'
      >
        <SmallScreen>
          <BodyTitle hideMenu={true} />
        </SmallScreen>
        <Picture
          height={isMobile ? '227' : '196'}
          src={donutsImage}
          width={isMobile ? '360' : '323'}
        />
        <Spacer.Horizontal size={isMobile ? 24 : 16} />
        <Column
          alignItems='center'
          justifyContent='center'
        >
          <BigScreen>
            <Text
              fontSize='2xl'
              fontWeight='semibold'
              textAlign='center'
            >
              ¡Auch! No pudimos mostrar tu carro
            </Text>
          </BigScreen>
          <SmallScreen>
            <>
              <Text
                fontSize='xl'
                fontWeight='semibold'
                textAlign='center'
              >
                ¡Auch!
              </Text>
              <Text
                fontSize='xl'
                fontWeight='semibold'
                textAlign='center'
              >
                No pudimos mostrar tu carro
              </Text>
            </>
          </SmallScreen>
          <Spacer.Horizontal size={isMobile ? 8 : 12} />
          <Column
            alignItems='center'
            justifyContent='center'
          >
            <Text
              fontSize={isMobile ? 'lg' : 'xl'}
              textAlign='center'
            >
              Tenemos problemas con cargar tus productos.
            </Text>
            <Text
              fontSize={isMobile ? 'lg' : 'xl'}
              textAlign='center'
            >
              Por favor, inténtalo otra vez.
            </Text>
          </Column>
          <Spacer.Horizontal size={64} />
          <Button
            borderRadius={getGlobalStyle('--border-radius-full')}
            fontSize={
              isMobile
                ? getGlobalStyle('--font-size-lg')
                : getGlobalStyle('--font-size-xl')
            }
            fontWeight={getGlobalStyle('--font-weight-medium')}
            height={isMobile ? '40px' : '44px'}
            label='Reintentar'
            onClick={() => {
              return router.reload()
            }}
            type='primary-gray'
            width={isMobile ? '343px' : '300px'}
          />
          <Spacer.Horizontal size={16} />
          <Button
            borderRadius={getGlobalStyle('--border-radius-full')}
            fontSize={
              isMobile
                ? getGlobalStyle('--font-size-lg')
                : getGlobalStyle('--font-size-xl')
            }
            fontWeight={getGlobalStyle('--font-weight-medium')}
            height={isMobile ? '40px' : '44px'}
            label='Volver al inicio'
            onClick={redirectToHome}
            type='secondary-gray'
            width={isMobile ? '343px' : '300px'}
          />
        </Column>
      </Row>
    </Container>
  )
}
