import { useEffect } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { globalConfig } from '../helpers/tooltipGlobalConfig'
import { JustifyContent } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import styles from './TooltipError.module.css'

export interface TooltipErrorProps {
  codeError?: string
  customJustifyContent?: JustifyContent
  customRight?: string
  errorMessage: string
  onClose: () => void
}

export const TooltipError = ({
  codeError,
  customJustifyContent,
  customRight,
  errorMessage,
  onClose
}: TooltipErrorProps): React.ReactElement => {
  const { isMobile } = useMobile()

  const {
    position,
    topMobile,
    height,
    maxWidth,
    width,
    rightMobile,
    leftMobile,
    sizeAlertModalIcon,
    paddingModal: padding
  } = globalConfig(isMobile)

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 4000)
  }, [])

  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-error-bg')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-neutral-black')}
      closeIcon={true}
      customJustifyContent={customJustifyContent ?? 'center'}
      display='flex'
      height={height}
      left={leftMobile}
      maxWidth={maxWidth}
      onClick={onClose}
      padding={padding}
      position={position}
      positionContainer='sticky'
      right={customRight ?? rightMobile}
      sizeCloseIcon={16}
      textComponent={
        <Container maxHeight='60px'>
          <Row alignItems='center'>
            <Column maxWidth='max-content'>
              <Icon
                color={getGlobalStyle('--color-feedback-error')}
                customSize={sizeAlertModalIcon}
                name='AlertModal'
              />
            </Column>
            <Spacer size={8} />
            <Column maxWidth='max-content'>
              <Text
                color='neutral-black'
                display='initial'
                fontSize='md'
                fontWeight='medium'
                lineHeight='15px'
              >
                {errorMessage || ''}
                {codeError && (
                  <Text
                    color='gray'
                    customClassName={styles.textMargin}
                    display='initial'
                    fontSize='2xs'
                    type='span'
                  >
                    ({codeError})
                  </Text>
                )}
              </Text>
            </Column>
          </Row>
        </Container>
      }
      top={topMobile}
      type='bottom'
      width={width}
    />
  )
}
