import { Container, Icon, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

interface SwiperButtonProps {
  onClick?: () => void
}

export const SwiperButton = ({ onClick }: SwiperButtonProps) => {
  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-primary-red2')}
      clickable='pointer'
      gap='10px'
      justifyContent='center'
      minHeight='100%'
      onClick={onClick}
      width='164px'
    >
      <Icon
        color={getGlobalStyle('--color-base-white')}
        customSize={20}
        name='Trash'
      />
      <Text
        color='white'
        fontSize='sm'
        lineHeight='0'
      >
        Eliminar
      </Text>
    </Container>
  )
}
