import { useState, useEffect, useRef } from 'react'
import { Container, QuantityButton } from '@smu-chile/pkg-unimarc-components'
import { ICartPrice } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export const DEFAULT_CART_LIMIT = 100

const validateValueToShow = (value: string): string => {
  if (value === null || value === undefined) {
    return ''
  }
  value = value.toLowerCase()
  // return 'un' just in case value doesn't have meansurement unit
  if (!value.includes('kg')) {
    return 'un'
  }
  return ''
}

interface QuantityWrapperProps {
  isCartLimit: boolean
  isLoadingQuantity: boolean
  isMobile: boolean
  price: ICartPrice
  quantity: number
  showValue: string
  changeQuantity: (element) => void
  restQuantity: () => void
  sumQuantity: () => void
}

export const QuantityWrapper = ({
  isCartLimit,
  isLoadingQuantity,
  isMobile,
  price,
  quantity,
  showValue,
  changeQuantity,
  restQuantity,
  sumQuantity
}: QuantityWrapperProps) => {
  const isMountedRef = useRef(true) // Ref to track if the component is mounted
  const timeoutRef = useRef(null) // Ref to store the ID of the current timeout
  const [displayButtons, setDisplayButtons] = useState(false)
  const textCustomColor = isLoadingQuantity
    ? getGlobalStyle('--color-neutral-gray-silver')
    : getGlobalStyle('--color-base-black')

  // Function to handle clicks on quantity container
  const handleClickOnContainer = async () => {
    if (isLoadingQuantity) return
    setDisplayButtons(true)

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // Set a new timeout to hide the buttons after 2000ms
    timeoutRef.current = setTimeout(() => {
      if (isMountedRef.current) {
        // Hide the buttons if the component is still mounted
        setDisplayButtons(false)
      }
    }, 4000)
  }

  // Function to handle the increment of quantity
  const handleSumQuantity = () => {
    if (timeoutRef.current) {
      // Clear the existing timeout
      clearTimeout(timeoutRef.current)
    }
    // Call the provided sumQuantity function
    sumQuantity()
  }

  // Function to handle the decrement of quantity
  const handleRestQuantity = () => {
    if (timeoutRef.current) {
      // Clear the existing timeout
      clearTimeout(timeoutRef.current)
    }
    // Call the provided restQuantity function
    restQuantity()
  }

  // Effect to hide buttons when quantity is loading
  useEffect(() => {
    if (isLoadingQuantity) {
      setDisplayButtons(false)
    }
  }, [isLoadingQuantity])

  // Effect to handle component mount and unmount
  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
      if (timeoutRef.current) {
        // Clear the existing timeout on unmount
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Container
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        position: isMobile ? 'absolute' : 'relative',
        right: 0,
        width: '100%'
      }}
    >
      <QuantityButton
        disabled={isLoadingQuantity}
        disabledPlus={isCartLimit}
        displayButtons={displayButtons}
        handleAdd={handleSumQuantity}
        handleChange={changeQuantity}
        handleClickOnContainer={handleClickOnContainer}
        handleOnMouseEnter={isMobile ? null : handleClickOnContainer}
        handleRemove={handleRestQuantity}
        isEditable={false}
        isLoadingQuantity={isLoadingQuantity}
        maxQuantity={
          price?.cartLimit > 0 ? price?.cartLimit : DEFAULT_CART_LIMIT
        }
        quantity={quantity}
        quantityButtonID={{
          addButtonID: getBemId('shelfAddQuantity', 'container'),
          quantityID: getBemId('shelfQuantity', 'container'),
          removeButtonID: getBemId('shelfRemoveQuantity', 'container')
        }}
        quantityButtonStyles={{
          borderRadius: '30px',
          fontSize: 'md',
          fontWeight: 'semibold',
          maxHeight: '34px',
          maxWidth: displayButtons ? '130px' : '78px',
          minWidth: displayButtons ? '130px' : '78px',
          maxHeightIcon: isMobile ? '30px' : '32px',
          sizeCircle: 16,
          padding: '0px 2px'
        }}
        showMaxMessage={isCartLimit}
        textAfterQuantity={validateValueToShow(showValue)}
        textBeforeQuantity={false}
        textCustomColor={textCustomColor}
        theme='white'
        value={showValue}
      />
    </Container>
  )
}
