import {
  Container,
  Column,
  Text,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { SquareButton } from './SquareButton'
import { v4 as uuid } from 'uuid'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export interface TimeOptionsProps {
  availableTimes: IAvailableWindows[]
  isMobile?: boolean | string
  scheduleSelected: IAvailableWindows
  handleSchedule: (date: IAvailableWindows) => void
}

export const TimeOptions = ({
  availableTimes,
  isMobile = false,
  scheduleSelected,
  handleSchedule
}: TimeOptionsProps): React.ReactElement => {
  return (
    <Container>
      <Column id={getBemId('shipmentHour', 'container')}>
        <Text
          fontSize={isMobile ? 'md' : 'lg'}
          fontWeight='medium'
          id={getBemId('shipmentHour', 'title')}
        >
          Hora
        </Text>
        <Spacer.Horizontal size={20} />
        <Row
          gap={23}
          id={getBemId('shipment', 'hoursContainer')}
          isWrap
        >
          {isValidArrayWithData(availableTimes) &&
            availableTimes.map((window, index) => {
              return (
                <SquareButton
                  date={window}
                  endDate={window?.endDateUtc}
                  handleSchedule={handleSchedule}
                  index={index}
                  isMobile={isMobile}
                  key={uuid()}
                  price={window?.price}
                  scheduleSelected={scheduleSelected}
                  startDate={window?.startDateUtc}
                />
              )
            })}
        </Row>
      </Column>
    </Container>
  )
}
