import { useEffect, useRef, useState } from 'react'
import {
  Row,
  Column,
  Spacer,
  Chip,
  Icon,
  Text,
  NotificationBubble,
  Container
} from '@smu-chile/pkg-unimarc-components'

import { Image } from './Image'
import { Name } from './Name'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ProductDetailsProps {
  name: string
  img: string
  isMobile?: boolean
  isNotAvailableProduct?: boolean
  leftQuantity?: number
  measurementUnit?: string
}

export const ProductDetails = ({
  img,
  isMobile,
  name,
  isNotAvailableProduct,
  leftQuantity,
  measurementUnit
}: ProductDetailsProps): React.ReactElement => {
  const bubbleRef = useRef(null)
  const buttonRef = useRef(null)
  const [showBubble, setShowBubble] = useState(false)

  const handleShowBubble = (value: boolean) => {
    setShowBubble(value)
  }

  // useEffect to add and clear the event listener
  useEffect(() => {
    // function to handle the click outside the tooltip
    function handleClickOutside(event) {
      // Verify that the click is not on the button or inside the tooltip
      if (
        bubbleRef.current &&
        !bubbleRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        handleShowBubble(false)
      }
    }

    // Add the event listener
    document.addEventListener('click', handleClickOutside)

    // Clear the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <Row
      alignItems='center'
      justifyContent='start'
    >
      <Column
        id={getBemId('shelfImage', 'container')}
        justifyContent='start'
        style={{
          maxWidth: 'max-content',
          opacity: isNotAvailableProduct ? 0.5 : 1
        }}
      >
        <Image
          altPicture={name}
          srcPicture={img}
        />
      </Column>
      <Spacer.Vertical customSize={isMobile ? 10 : 20} />
      <Column
        alignItems='start'
        maxWidth={isNotAvailableProduct ? '252px' : '270px'}
      >
        {isNotAvailableProduct && (
          <Container position='relative'>
            <Row
              alignItems='center'
              clickable='pointer'
              maxWidth='max-content'
              onClick={() => {
                return handleShowBubble(true)
              }}
              ref={buttonRef}
            >
              <Chip
                backgroundColor={getGlobalStyle('--color-neutral-gray-hover')}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                color={getGlobalStyle('--color-base-black')}
                label='Pronto disponible'
                padding='4px'
              />
              <Spacer.Vertical size={2} />
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-base-black')}
                customSize={12}
                name='AlertIcon'
              />
            </Row>
            {showBubble && (
              <NotificationBubble
                arrowPosition='top'
                customLeft='-29px'
                customPadding='4px 8px'
                customTop='24px'
                fontSize='sm'
                handleClickOnIcon={() => {
                  return handleShowBubble(false)
                }}
                height='62px'
                hideIcon={false}
                iconName='CloseThin'
                iconSize={14}
                label='¡Vuelven en un rato!  🚀'
                maxWidth='297px'
                minWidth='297px'
                ref={bubbleRef}
                spaceBetweenLabels={isMobile ? 1 : 4}
                subLabel=' Actualizamos nuestro stock con frecuencia, tu producto pronto podría estar disponible.'
              />
            )}
            <Spacer.Horizontal size={4} />
          </Container>
        )}
        <Name
          isNotAvailableProduct={isNotAvailableProduct}
          name={name}
        />
        {isNotAvailableProduct && (
          <>
            <Spacer.Horizontal size={4} />
            <Text
              color='gray'
              fontSize={isMobile ? 'xs' : 'md'}
            >
              {leftQuantity} {measurementUnit}
            </Text>
          </>
        )}
      </Column>
    </Row>
  )
}
