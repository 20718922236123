import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'

/**
 * Retrieves unique days from the available windows.
 * @param {IAvailableWindows} availableWindows - The list of available windows.
 * @returns {Array} - An array containing unique days.
 * @example
 * const availableWindows = [
 *   { startDateUtc: "2024-02-28T08:00:00" },
 *   { startDateUtc: "2024-02-28T12:00:00" },
 *   { startDateUtc: "2024-03-01T08:00:00" },
 *   { startDateUtc: "2024-03-01T12:00:00" },
 *   { startDateUtc: "2024-03-02T08:00:00" },
 * ];
 * const uniqueDays = getUniqueDays(availableWindows);
 * Output: [{ startDateUtc: "2024-02-28T08:00:00" }, { startDateUtc: "2024-03-01T08:00:00" }, { startDateUtc: "2024-03-02T08:00:00" }]
 */
export const getUniqueDays = (
  availableWindows: IAvailableWindows[]
): IAvailableWindows[] => {
  const uniqueDays = []
  const daysGotten = {}

  if (isValidArrayWithData(availableWindows)) {
    availableWindows.forEach(function (window) {
      const day = window.startDateUtc.split('T')[0]

      if (!daysGotten[day]) {
        uniqueDays.push(window)
        daysGotten[day] = true
      }
    })
  }

  return uniqueDays
}
