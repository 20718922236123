import {
  Column,
  Container,
  Row,
  Text,
  Spacer,
  Button
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { AddressIcon } from './AddressIcon'
import { getAddressParams } from './helpers/getAddressParams'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'
import { ISelectedAddress } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import styles from './AddressSelected.module.css'

export interface AddressSelectedProps {
  fromMyShipment?: boolean
  isLoading?: boolean
  isMobile: boolean
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  selectedAddress: ISelectedAddress
}

export const AddressSelected = ({
  fromMyShipment = false,
  isLoading = false,
  isMobile,
  objetSrcImagesIconAddress,
  selectedAddress
}: AddressSelectedProps): React.ReactElement => {
  const { addressParams, addressStyle } = getAddressParams(
    selectedAddress,
    objetSrcImagesIconAddress,
    isMobile,
    fromMyShipment
  )

  const handleChangeAddress = () => {
    if (selectedAddress?.addressType === 'residential') {
      return trigger({
        eventType: 'storeLocator',
        data: { show: true }
      })
    }
    return trigger({
      eventType: 'deliverySelector',
      data: { show: true }
    })
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-primary-red-bg')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      id={getBemId('address', 'container')}
      isWrap={!fromMyShipment}
      margin={addressStyle.containerMargin}
      minHeight={addressStyle.minHeightContainer}
      padding={addressStyle.paddingContainer}
    >
      <Row
        alignItems='center'
        customWidth='100%'
        justifyContent='between'
        maxWidth={addressStyle.maxWidthContainer}
      >
        <Column>
          <Container
            alignItems='center'
            id={getBemId('addressType', 'container')}
          >
            <AddressIcon
              iconSize={addressStyle.iconSize}
              srcImage={addressParams?.srcImageIconAddress}
            />
            {!fromMyShipment && (
              <>
                <Spacer.Vertical size={8} />
                <Column>
                  <Text
                    color='black'
                    fontSize={isMobile ? 'md' : 'xl'}
                    fontWeight='medium'
                  >
                    {addressParams?.typeOfAddress}
                  </Text>
                </Column>
              </>
            )}
          </Container>
        </Column>
        {!fromMyShipment && (
          <Column maxWidth={isMobile ? '55px' : '75px'}>
            <Button
              actionType='button'
              border='none'
              borderRadius={getGlobalStyle('--border-radius-xl')}
              color={
                isLoading
                  ? getGlobalStyle('--color-primary-disabled')
                  : getGlobalStyle('--color-primary-light-red')
              }
              fontSize={isMobile ? '12px' : '14px'}
              fontWeight='600'
              height={isMobile ? '19px' : '23px'}
              id={getBemId('changeAddress', 'button')}
              label='Cambiar'
              maxWidth='fit-content'
              minWidth='fit-content'
              onClick={handleChangeAddress}
              padding='0'
              status={isLoading ? 'disabled' : 'initial'}
              themePointLoader='red'
              type='plain'
            />
          </Column>
        )}
      </Row>
      {!fromMyShipment && <Spacer.Horizontal size={isMobile ? 8 : 4} />}
      <Row
        alignItems='center'
        minHeight='24px'
      >
        <Column
          customClassName={styles.textBlock}
          id={getBemId('addressSelected', 'container')}
        >
          {selectedAddress?.addressType === 'pickup-in-point' && (
            <Text
              display='inline'
              fontSize={isMobile ? 'sm' : 'lg'}
              fontWeight='medium'
              type='span'
            >
              Unimarc {selectedAddress?.friendlyName ?? ''}.
            </Text>
          )}
          <Text
            color='black'
            display='inline'
            fontSize={isMobile ? 'sm' : 'lg'}
            truncate={70}
          >
            {addressParams?.formatedAddress}
          </Text>
        </Column>
      </Row>
    </Container>
  )
}
