import {
  Spacer,
  Row,
  Column,
  Icon,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { ISimulationResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISimulation'

interface TotalUnipayProps {
  simulationData: ISimulationResponse
}

export const TotalUnipay = ({
  simulationData
}: TotalUnipayProps): React.ReactElement => {
  return (
    <>
      <Spacer.Horizontal size={8} />
      <Row
        alignItems='center'
        id={getBemId('subtotalUnipay', 'container')}
        justifyContent='between'
      >
        <Column
          maxWidth='177px'
          minWidth='177px'
        >
          <Text
            color='black'
            fontWeight='semibold'
            id={getBemId('subtotalUnipay', 'text')}
          >
            Subtotal Unipay
          </Text>
        </Column>
        <Column width='fit-content'>
          <Row alignItems='center'>
            <div id={getBemId('subtotalUnipayIcon', 'container')}>
              <Icon
                customSize={16}
                name='Unipay2'
              />
            </div>
            <Spacer.Vertical size={4} />
            <Text
              color='primary'
              fontWeight='semibold'
              id={getBemId('subtotalUnipayPrice', 'text')}
            >
              {simulationData?.simulations?.[0]?.subTotal}
            </Text>
          </Row>
        </Column>
      </Row>
    </>
  )
}
