import { Row, Column, Spacer } from '@smu-chile/pkg-unimarc-components'

import { Image } from './Image'
import { Name } from './Name'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

interface ProductShelfProps {
  measurement: string
  name: string
  img: string
  isMobile?: boolean
  quantity: number
}

export const ProductShelf = ({
  img,
  measurement,
  quantity,
  isMobile,
  name
}: ProductShelfProps): React.ReactElement => {
  return (
    <Row
      alignItems='center'
      id={getBemId('restockedModal', 'product')}
      justifyContent='between'
      minHeight={isMobile ? '67px' : '88px'}
    >
      <Column
        id={getBemId('restockedModal', 'product', 'image')}
        justifyContent='start'
        maxWidth='max-content'
      >
        <Image
          altPicture={name}
          customPaddingMobile='0px'
          customSizeMobile={60}
          srcPicture={img}
        />
      </Column>
      <Spacer.Vertical customSize={isMobile ? 10 : 30} />
      <Column
        alignItems='center'
        customWidth={isMobile ? '80' : '72'}
      >
        <Name
          isMobile={isMobile}
          measurement={measurement}
          name={name}
          quantity={quantity}
        />
      </Column>
    </Row>
  )
}
