import React from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ShelfDeleteItem.module.css'

interface ShelfDeleteItemProps {
  isLoading: boolean
  isMobile: boolean
  onDelete: () => void
}

export const ShelfDeleteItem = ({
  isLoading,
  isMobile,
  onDelete
}: ShelfDeleteItemProps) => {
  if (isMobile) {
    return (
      <Container
        absoluteDefault='topRight'
        alignItems='end'
        justifyContent='end'
        maxWidth='16px'
        padding='12px 32px 12px 16px'
        position='absolute'
      >
        <Row
          id={getBemId('shelfDeleteItem', 'container')}
          onClick={onDelete}
        >
          <Column>
            <Icon
              color={getGlobalStyle('--color-icons-gray')}
              customSize={12}
              name='Close'
            />
          </Column>
        </Row>
      </Container>
    )
  }

  return (
    <Container
      alignItems='center'
      justifyContent='end'
      minHeight='26px'
    >
      <Row justifyContent='end'>
        <Column
          customClassName={styles.iconSize}
          id={getBemId('shelfDeleteItem', 'container')}
          maxWidth='max-content'
          onClick={onDelete}
        >
          {!isLoading ? (
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-icons-gray')}
              customSize={14}
              name='Close'
            />
          ) : (
            <Spacer.Horizontal customSize={14} />
          )}
        </Column>
      </Row>
    </Container>
  )
}
