import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { globalConfig } from '../helpers/tooltipGlobalConfig'

export const TooltipAlcohol = ({
  onClose
}: {
  onClose: () => void
}): React.ReactElement => {
  const { isMobile } = useMobile()

  const {
    position,
    topMobile,
    width,
    rightMobile,
    leftMobile,
    sizeAlertModalIcon,
    paddingModal
  } = globalConfig(isMobile)

  return (
    <Tooltip
      alignAllItems='center'
      background='#BEF0FB'
      bottom=''
      boxShadow='0px 6px 10px rgba(27, 33, 56, 0.15)'
      closeColor={getGlobalStyle('--color-neutral-black')}
      closeIcon
      display='flex'
      height='56px'
      id={getBemId('tooltipAlcohol', 'container')}
      left={leftMobile}
      maxWidth='377px'
      onClick={onClose}
      padding={paddingModal}
      position={position}
      positionContainer='sticky'
      right={rightMobile}
      sizeCloseIcon={16}
      text=''
      textComponent={
        <Container maxHeight='60px'>
          <Row alignItems='center'>
            <Column maxWidth='max-content'>
              <Icon
                color='#1FA5C2'
                customSize={sizeAlertModalIcon}
                name='AlertModal'
              />
            </Column>
            <Spacer size={8} />
            <Column maxWidth='max-content'>
              <Text
                color={'neutral-black'}
                fontSize='md'
                fontWeight='medium'
                id={getBemId('tooltipAlcohol', 'text')}
                lineHeight='15px'
              >
                Deberás mostrar tu carnet al recibir compras de bebidas
                alcohólicas.
              </Text>
            </Column>
          </Row>
        </Container>
      }
      top={topMobile}
      type='bottom'
      width={width}
    />
  )
}
