import React, { useEffect } from 'react'
import { Tooltip } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import TextComponent from './TextComponent'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { globalConfig } from '../helpers/tooltipGlobalConfig'
import { JustifyContent } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'

interface ToastMenssageProps {
  customJustifyContent?: JustifyContent
  customRight?: string
  message?: string
  onClose?: () => void
}

export const ToastMessage = ({
  customJustifyContent,
  customRight,
  message,
  onClose
}: ToastMenssageProps) => {
  const { isMobile } = useMobile()
  const top = isMobile ? '62px' : '12px'
  const { position, width, rightMobile, paddingModal } = globalConfig(isMobile)

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 4000)
  })

  return (
    <Tooltip
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-success-bg')}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-icons-black')}
      closeIcon
      customJustifyContent={customJustifyContent ?? 'center'}
      display='flex'
      height='56px'
      id={getBemId('tooltipMessage', 'container')}
      maxWidth='377px'
      onClick={onClose}
      padding={paddingModal}
      position={position}
      positionContainer='sticky'
      right={customRight ?? rightMobile}
      textComponent={<TextComponent message={message} />}
      top={top}
      type='bottom'
      width={width}
    />
  )
}
