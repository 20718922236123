import { v4 as uuid } from 'uuid'
import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

export const BodyLoader = (): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <Container>
          <Column
            margin='0 56px 0 0'
            maxWidth='690px'
            minWidth='690px'
          >
            <Row>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={90}
                width={100}
              />
            </Row>
            <Spacer.Horizontal customSize={20} />
            <Container
              style={{
                borderBottom: `0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-light'
                )}`,
                padding: '10px 16px'
              }}
            >
              <Row maxWidth='473px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={23}
                  width={100}
                />
              </Row>
            </Container>
            {Array.from(Array(5)).map(() => {
              return (
                <Column
                  key={uuid()}
                  padding='12px 16px'
                >
                  <Row justifyContent='between'>
                    <Container
                      alignItems='center'
                      minWidth='480px'
                    >
                      <Column maxWidth='80px'>
                        <Backbone
                          height={80}
                          width={100}
                        />
                      </Column>
                      <Spacer.Vertical customSize={30} />
                      <Column>
                        <Row maxWidth='180px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={14}
                            width={100}
                          />
                        </Row>
                        <Spacer.Horizontal customSize={6} />
                        <Row maxWidth='140px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={14}
                            width={100}
                          />
                        </Row>
                      </Column>
                      <Spacer.Vertical customSize={30} />
                      <Column>
                        <Container>
                          <Row maxWidth='86px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-md'
                              )}
                              height={14}
                              width={100}
                            />
                          </Row>
                          <Spacer.Vertical customSize={4} />
                          <Row maxWidth='28px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-xs'
                              )}
                              height={14}
                              width={100}
                            />
                          </Row>
                        </Container>
                        <Spacer.Horizontal customSize={6} />
                        <Row maxWidth='86px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={14}
                            width={100}
                          />
                        </Row>
                      </Column>
                    </Container>
                    <Container
                      alignItems='center'
                      justifyContent='end'
                      minHeight='80px'
                    >
                      <Row maxWidth='78px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-full')}
                          height={34}
                          width={100}
                        />
                      </Row>
                    </Container>
                  </Row>
                  <Spacer.Horizontal size={12} />
                  <Container>
                    {Array.from(Array(2)).map(() => {
                      return (
                        <Row
                          key={uuid()}
                          maxWidth='150px'
                        >
                          <Column maxWidth='15px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-full'
                              )}
                              height={15}
                              width={100}
                            />
                          </Column>
                          <Spacer.Vertical size={4} />
                          <Column maxWidth='115px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-md'
                              )}
                              height={15}
                              width={100}
                            />
                          </Column>
                        </Row>
                      )
                    })}
                  </Container>
                  <Spacer.Horizontal size={8} />
                </Column>
              )
            })}
          </Column>
          <Row justifyContent='center'>
            {Array.from(Array(2)).map((_, index) => {
              return (
                <Row
                  alignItems='center'
                  justifyContent='center'
                  key={uuid()}
                  style={{
                    borderRight:
                      index === 0 &&
                      `0.3px solid ${getGlobalStyle(
                        '--color-neutral-gray-light'
                      )}`,
                    padding: '0 7px',
                    maxWidth: 'max-content'
                  }}
                >
                  <Container minWidth='140px'>
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={16}
                      width={100}
                    />
                  </Container>
                  <Spacer.Vertical customSize={6} />
                  <Container maxWidth='20px'>
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={20}
                      width={100}
                    />
                  </Container>
                </Row>
              )
            })}
          </Row>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Column padding='80px 16px 16px'>
          <Row>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={72}
              width={100}
            />
          </Row>
          <Spacer.Horizontal customSize={16} />
          <Container
            style={{
              borderBottom: `0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-light'
              )}`,
              paddingBottom: '10px'
            }}
          >
            <Row maxWidth='235px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={20}
                width={100}
              />
            </Row>
          </Container>
          <Spacer.Horizontal customSize={16} />
          <Container isWrap>
            {Array.from(Array(6)).map(() => {
              return (
                <Column
                  key={uuid()}
                  padding='8px'
                >
                  <Row justifyContent='between'>
                    <Container
                      alignItems='center'
                      minWidth='240px'
                    >
                      <Column maxWidth='50px'>
                        <Backbone
                          height={50}
                          width={100}
                        />
                      </Column>
                      <Spacer.Vertical customSize={10} />
                      <Column>
                        <Row maxWidth='140px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={12}
                            width={100}
                          />
                        </Row>
                        <Spacer.Horizontal customSize={6} />
                        <Row maxWidth='100px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={12}
                            width={100}
                          />
                        </Row>
                        <Spacer.Horizontal customSize={10} />
                        <Container>
                          <Column maxWidth='80px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-md'
                              )}
                              height={12}
                              width={100}
                            />
                          </Column>
                          <Spacer.Vertical customSize={4} />
                          <Column maxWidth='34px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-md'
                              )}
                              height={12}
                              width={100}
                            />
                          </Column>
                        </Container>
                        <Spacer.Horizontal customSize={6} />
                        <Row maxWidth='70px'>
                          <Backbone
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            height={12}
                            width={100}
                          />
                        </Row>
                      </Column>
                    </Container>
                    <Container
                      alignItems='center'
                      justifyContent='end'
                      minHeight='70px'
                    >
                      <Row maxWidth='73px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-full')}
                          height={34}
                          width={100}
                        />
                      </Row>
                    </Container>
                  </Row>
                  <Spacer.Horizontal size={12} />
                  <Container>
                    {Array.from(Array(2)).map(() => {
                      return (
                        <Row
                          key={uuid()}
                          maxWidth='130px'
                        >
                          <Column maxWidth='12px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-full'
                              )}
                              height={12}
                              width={100}
                            />
                          </Column>
                          <Spacer.Vertical size={4} />
                          <Column maxWidth='100px'>
                            <Backbone
                              borderRadius={getGlobalStyle(
                                '--border-radius-md'
                              )}
                              height={12}
                              width={100}
                            />
                          </Column>
                        </Row>
                      )
                    })}
                  </Container>
                  <Spacer.Horizontal size={8} />
                </Column>
              )
            })}
          </Container>
        </Column>
      </SmallScreen>
    </>
  )
}
