import { Column, Container, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { SHORT_DAYS } from 'shared/utils/constants'
import styles from '../../MyShipments.module.css'

export interface CircleButtonProps {
  daySelected: string
  index?: number
  isMobile: boolean | string
  startDate: string
  handleAvailableTimes: (day: string) => void
}

export const CircleButton = ({
  daySelected,
  index,
  isMobile,
  startDate,
  handleAvailableTimes
}: CircleButtonProps): React.ReactElement => {
  const startDay = new Date(startDate)
  const currentDate: Date = new Date()
  const day =
    startDay.getDate() === currentDate.getDate()
      ? 'Hoy'
      : SHORT_DAYS[startDay.getDay()]
  const dayNumber = startDay.getDate()
  return (
    <Container
      border={`0.3px solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius={getGlobalStyle('--border-radius-full')}
      clickable='pointer'
      customClassName={
        daySelected === startDate?.split('T')[0] ? styles.optionSelected : ''
      }
      id={getBemId('shipmentDate', 'day', index?.toString())}
      maxHeight={isMobile ? '70px' : '80px'}
      maxWidth={isMobile ? '70px' : '80px'}
      onClick={() => {
        return handleAvailableTimes(startDate)
      }}
    >
      <Column
        alignItems='center'
        clickable='pointer'
        customHeight={isMobile ? '70px' : '80px'}
        customWidth={isMobile ? '70px' : '80px'}
        justifyContent='center'
        minHeight={isMobile ? '70px' : '80px'}
        minWidth={isMobile ? '70px' : '80px'}
      >
        <Text
          clickable='pointer'
          fontSize='sm'
          fontWeight='regular'
          id={getBemId('shipmentDate', 'dayText', index?.toString())}
        >
          {day}
        </Text>
        <Text
          clickable='pointer'
          fontSize='md'
          fontWeight='semibold'
          id={getBemId('shipmentDate', 'dayNumber', index?.toString())}
        >
          {dayNumber}
        </Text>
      </Column>
    </Container>
  )
}
