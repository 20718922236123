import {
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import { CircleButton } from './CircleButton'
import { getUniqueDays } from '../helpers/getUniqueDays'
import { v4 as uuid } from 'uuid'
import styles from './DateOptions.module.css'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

export interface DateOptionsProps {
  availableWindows: IAvailableWindows[]
  daySelected: string
  isMobile?: boolean | string
  handleAvailableTimes: (day: string) => void
}

export const DateOptions = ({
  availableWindows,
  daySelected,
  isMobile = false,
  handleAvailableTimes
}: DateOptionsProps) => {
  const availableDays = getUniqueDays(availableWindows)
  return (
    <Container>
      <Column id={getBemId('shipmentDate', 'container')}>
        <Text
          fontSize={isMobile ? 'md' : 'lg'}
          fontWeight='medium'
          id={getBemId('shipmentDate', 'title')}
        >
          Fecha
        </Text>
        <Spacer.Horizontal size={20} />
        <Row
          className={styles.dayOptions___container}
          id={getBemId('shipment', 'daysContainer')}
        >
          {isValidArrayWithData(availableWindows) &&
            availableDays.map((window, index) => {
              return (
                <CircleButton
                  daySelected={daySelected}
                  handleAvailableTimes={handleAvailableTimes}
                  index={index}
                  isMobile={isMobile}
                  key={uuid()}
                  startDate={window?.startDateUtc}
                />
              )
            })}
        </Row>
      </Column>
    </Container>
  )
}
