import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import {
  getMyUsualsAndFavorites,
  isValidArrayWithData,
  trigger,
  useEvents
} from '@smu-chile/pkg-unimarc-hooks'
import {
  IGetMyUsualsAndFavoritesData,
  IProductsList
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

export const PRODUCTS_PER_PAGE = 10

export interface IUseMyUsualsAndFavorites {
  isLoading: boolean
  myUsualsAndFavoritesData: IGetMyUsualsAndFavoritesData
  handleChancePage: () => void
  handleOpenMyUsualsAndFavorites: () => void
}

/**
 * Custom hook to manage and fetch "My Usuals and Favorites" data.
 * @returns {object} The hook's state and functions.
 */
export const useMyUsualsAndFavorites = (
  cartData: ICart,
  isFetchingCart: boolean,
  openMyUsuals: boolean
): IUseMyUsualsAndFavorites => {
  const router = useRouter()
  const previousCartData = useRef<ICart>()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPagesMyUsuals, setTotalPagesMyUsuals] = useState(1)
  const [totalPagesFavorites, setTotalPagesFavorites] = useState(1)
  const [isWaitingToExecute, setIsWaitingToExecute] = useState(false)
  const [isFromMyUsuals, setIsFromMyUsuals] = useState(false)
  const [myUsualsAndFavoritesData, setMyUsualsAndFavoritesData] =
    useState<IGetMyUsualsAndFavoritesData>()

  useEvents({
    eventType: 'updatingFromMyUsuals',
    callBack: ({ detail: { isUpdating } }) => {
      setIsFromMyUsuals(isUpdating)
    }
  })

  /**
   * Checks if two products are equal based on their item IDs.
   *
   * @param {IProductsList} product1 - The first product to compare.
   * @param {IProductsList} product2 - The second product to compare.
   * @returns {boolean} True if the products are equal, false otherwise.
   */
  const areProductsEqual = (
    product1: IProductsList,
    product2: IProductsList
  ) => {
    return product1.item?.itemId === product2.item?.itemId
  }

  /**
   * Filters out duplicate products from the new products list.
   *
   * @param {IProductsList[]} newProducts - The new products to filter.
   * @param {IProductsList[]} existingProducts - The existing products to compare against.
   * @returns {IProductsList[]} The filtered list of unique products.
   */
  const getUniqueProducts = (
    newProducts: IProductsList[],
    existingProducts: IProductsList[]
  ) => {
    return newProducts.filter((newProduct) => {
      return !existingProducts.some((prevProduct) => {
        return areProductsEqual(prevProduct, newProduct)
      })
    })
  }

  /**
   * Fetches "My Usuals and Favorites" data from the server.
   *
   * @param {number} [page=1] - The page number to fetch.
   * @returns {Promise<IGetMyUsualsAndFavoritesResponse>} The server response.
   */
  const handleGetMyUsualsAndFavorites = async (
    page = 1
  ): Promise<IGetMyUsualsAndFavoritesData> => {
    if (page === 1) {
      setCurrentPage(1)
      setMyUsualsAndFavoritesData(null)
    }
    setIsLoading(true)
    const response = await getMyUsualsAndFavorites({
      page: page.toString(),
      limit: PRODUCTS_PER_PAGE.toString()
    })

    if (
      response?.error ||
      (!isValidArrayWithData(response?.data?.favorites?.products) &&
        !isValidArrayWithData(response?.data?.myUsuals?.products))
    ) {
      setIsLoading(false)
      return response?.data
    }

    setMyUsualsAndFavoritesData((prevData: IGetMyUsualsAndFavoritesData) => {
      if (!prevData) {
        return response?.data
      }

      const uniqueFavoritesProducts = getUniqueProducts(
        response.data.favorites.products,
        prevData.favorites.products
      )
      const uniqueMyUsualsProducts = getUniqueProducts(
        response.data.myUsuals.products,
        prevData.myUsuals.products
      )

      return {
        favorites: {
          ...response?.data?.favorites,
          products: [...prevData.favorites.products, ...uniqueFavoritesProducts]
        },
        myUsuals: {
          ...response?.data?.myUsuals,
          products: [...prevData.myUsuals.products, ...uniqueMyUsualsProducts]
        }
      }
    })
    setTotalPagesMyUsuals(response?.data?.myUsuals?.totalPages)
    setTotalPagesFavorites(response?.data?.favorites?.totalPages)
    setIsLoading(false)
    return response?.data
  }

  /**
   * Handles changing to the next page of "My Usuals and Favorites".
   */
  const handleChancePage = () => {
    if (totalPagesFavorites > 1 || totalPagesMyUsuals > 1) {
      handleGetMyUsualsAndFavorites(currentPage + 1)
      setCurrentPage(currentPage + 1)
    }
  }

  /**
   * Handles the logic for opening "My Usuals and Favorites".
   * If the `isLoading` state is true, it sets a flag to execute the logic later.
   * Otherwise, it directly executes the required logic.
   */
  const handleOpenMyUsualsAndFavorites = () => {
    if (isLoading) {
      setIsWaitingToExecute(true)
    } else {
      executeLogic()
    }
  }

  /**
   * Executes the main logic for "My Usuals and Favorites".
   * If there is valid data in `myUsualsAndFavoritesData`, it triggers an event to show the data.
   * Otherwise, it redirects to the "My Shipments" page.
   */
  const executeLogic = () => {
    if (
      isValidArrayWithData(myUsualsAndFavoritesData?.favorites?.products) ||
      isValidArrayWithData(myUsualsAndFavoritesData?.myUsuals?.products)
    ) {
      trigger({ eventType: 'myUsuals', data: { show: true } })
    } else {
      router.push('/MyShipments')
    }
  }

  /**
   * Checks if the logic should be executed after loading completes.
   * If `isLoading` becomes false and the `isWaitingToExecute` flag is true, it executes the logic
   * and resets the flag.
   */
  useEffect(() => {
    if (!isLoading && isWaitingToExecute) {
      executeLogic()
      setIsWaitingToExecute(false)
    }
  }, [isLoading, isWaitingToExecute])

  /**
   * Compare if the current cart data is different from the previous data
   */
  useEffect(() => {
    if (
      !isFromMyUsuals &&
      !openMyUsuals &&
      cartData &&
      JSON.stringify(previousCartData.current) !== JSON.stringify(cartData)
    ) {
      handleGetMyUsualsAndFavorites()
    }

    // Updates the previous value with the current cart data
    previousCartData.current = cartData
  }, [cartData, isFetchingCart, openMyUsuals, isFromMyUsuals])

  /**
   * Get dont forget service after close my usuals
   */
  useEffect(() => {
    if (!openMyUsuals) {
      setIsFromMyUsuals(false)
      handleGetMyUsualsAndFavorites()
    }
  }, [openMyUsuals])

  return {
    handleChancePage,
    handleOpenMyUsualsAndFavorites,
    isLoading,
    myUsualsAndFavoritesData
  }
}
