import NextImage from 'next/image'
import { Row, Column, Picture } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { formatURLfromVtex, useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface ImageProps {
  customPaddingMobile?: string
  customSizeMobile?: number
  srcHighlight?: string
  altHighlight?: string
  srcPicture: string
  altPicture: string
}

type ImageLoader = {
  src: string
}

const reBuildImageUrlFromVtex = (imageURL: string, size: number): string => {
  return formatURLfromVtex(imageURL, size, size)
}

const imageNextJsLoader = ({ src }: ImageLoader) => {
  return `${src}&q=75`
}

export const Image = ({
  customPaddingMobile = '0 0 0 14px',
  customSizeMobile = 70,
  srcHighlight,
  altHighlight,
  srcPicture,
  altPicture
}: ImageProps): React.ReactElement<ImageProps> => {
  const { isMobile } = useMobile()
  const sizeByDevice = isMobile ? 140 : 250
  const reBuildedImgSrc: string = reBuildImageUrlFromVtex(
    srcPicture,
    sizeByDevice
  )
  const reBuildedImgFlag: string = reBuildImageUrlFromVtex(
    srcHighlight,
    sizeByDevice
  )
  return (
    <Column>
      {/* promotional flags */}
      <Row>
        {srcHighlight && (
          <Picture
            alt={altHighlight}
            border='none'
            borderRadius='none'
            height='auto'
            src={reBuildedImgFlag}
            width='auto'
          />
        )}
      </Row>

      {/* Desktop */}
      <BigScreen>
        <Row justifyContent='start'>
          <Column
            maxHeight='80px'
            maxWidth='80px'
          >
            <NextImage
              alt={altPicture}
              height={80}
              loader={imageNextJsLoader}
              objectFit='cover'
              src={reBuildedImgSrc}
              width={80}
            />
          </Column>
        </Row>
      </BigScreen>

      {/* Mobile */}
      <SmallScreen>
        <Row
          justifyContent='center'
          padding={customPaddingMobile}
        >
          <Column
            minHeight={customSizeMobile.toString()}
            minWidth={customSizeMobile.toString()}
          >
            <NextImage
              alt={altPicture}
              height={customSizeMobile}
              loader={imageNextJsLoader}
              objectFit='cover'
              src={reBuildedImgSrc}
              width={customSizeMobile}
            />
          </Column>
        </Row>
      </SmallScreen>
    </Column>
  )
}
