import {
  Button,
  Column,
  Container,
  Horizontal,
  Picture,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { eventClick, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { BodyTitle } from '../BodyTitle'
import styles from './EmptyCart.module.css'
import stylesBody from '../Body.module.css'

const handleGoToHome = () => {
  eventClick({
    event: 'interacciones_carrito',
    eventAction: 'carro_vacio',
    eventCategory: 'carrito',
    eventLabel: 'ir a la página principal'
  })
}

export const EmptyCart = ({
  onionImage
}: {
  onionImage?: string
}): React.ReactElement => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const redirectToHome = () => {
    handleGoToHome()
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-base-white')}
      customClassName={stylesBody.customHeight}
      justifyContent='center'
      margin={`${isMobile ? '116px' : '24px'} 0 0 0`}
      tagName='section'
    >
      <Container
        customWidth='90'
        isWrap
        justifyContent='center'
        maxWidth='1440px'
      >
        <SmallScreen>
          <BodyTitle hideMenu={true} />
        </SmallScreen>
        <Picture
          height='200'
          id={getBemId('emptyCart', 'image')}
          src={onionImage}
          width='360'
        />
        <Horizontal size={24} />
        <BigScreen>
          {
            <>
              <Text
                customClassName={styles.title__desktop}
                id={getBemId('emptyCart', 'title')}
                textAlign='center'
              >
                Tu carro está vacío
              </Text>
              <Horizontal size={12} />
            </>
          }
        </BigScreen>

        <Column
          alignItems='center'
          justifyContent='center'
        >
          <SmallScreen>
            <>
              <Text
                customClassName={styles.title__mobile}
                id={getBemId('emptyCart', 'title')}
                textAlign='center'
              >
                Tu carrito está vacío
              </Text>
              <Horizontal size={8} />
            </>
          </SmallScreen>
          <Container
            alignItems='center'
            justifyContent='center'
          >
            <Text
              customClassName={styles.subtitle}
              id={getBemId('emptyCart', 'subtitle')}
              textAlign='center'
            >
              Descubre todas las ofertas que tenemos para ti y llénalo
            </Text>
          </Container>
          <Horizontal size={64} />
        </Column>

        <Button
          borderRadius={getGlobalStyle('--border-radius-full')}
          fontSize={getGlobalStyle('--font-size-lg')}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height={isMobile ? '40px' : '44px'}
          id={getBemId('emptyCart', 'button')}
          label='Buscar productos'
          onClick={redirectToHome}
          type='primary-gray'
          width={isMobile ? '304px' : '300px'}
        />
        <BigScreen>
          <Horizontal size={160} />
        </BigScreen>
      </Container>
    </Container>
  )
}
