import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { v4 as uuid } from 'uuid'
import ButtonGoToPay from '../ButtonGoToPay'
import styles from '../DateOptions/DateOptions.module.css'

export const MyShipmentsLoader = () => {
  return (
    <Container
      isWrap
      margin='0 auto'
      maxWidth={getGlobalStyle('--width-max-desktop')}
    >
      <SmallScreen>
        <Spacer.Horizontal size={38} />
      </SmallScreen>
      <Column maxWidth='690px'>
        <BigScreen>
          <Column>
            <Backbone
              backgroundColor={getGlobalStyle('--color-background-gray-light')}
              borderRadius={getGlobalStyle('--border-radius-xs')}
              height={68}
              width={100}
            />
            <Spacer.Horizontal size={24} />
            <Spacer.Horizontal size={6} />
            <Row width={174}>
              <Backbone
                backgroundColor={getGlobalStyle(
                  '--color-background-gray-light'
                )}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={16}
                width={100}
              />
            </Row>
            <Spacer.Horizontal size={28} />
            <Row width={253}>
              <Backbone
                backgroundColor={getGlobalStyle(
                  '--color-background-gray-light'
                )}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={20}
                width={100}
              />
            </Row>
            <Spacer.Horizontal size={20} />
            <Column padding='0 10px'>
              <Row width={54}>
                <Backbone
                  backgroundColor={getGlobalStyle(
                    '--color-background-gray-light'
                  )}
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={14}
                  width={100}
                />
              </Row>
              <Spacer.Horizontal size={12} />
              <Row gap={20}>
                {Array.from(Array(5)).map(() => {
                  return (
                    <Container
                      key={uuid()}
                      width={80}
                    >
                      <Backbone
                        backgroundColor={getGlobalStyle(
                          '--color-background-gray-light'
                        )}
                        borderRadius={getGlobalStyle('--border-radius-full')}
                        height={80}
                        width={100}
                      />
                    </Container>
                  )
                })}
              </Row>
              <Spacer.Horizontal size={24} />
              <Row width={54}>
                <Backbone
                  backgroundColor={getGlobalStyle(
                    '--color-background-gray-light'
                  )}
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={14}
                  width={100}
                />
              </Row>
              <Spacer.Horizontal size={18} />
              <Row
                gap={37}
                isWrap
              >
                {Array.from(Array(9)).map(() => {
                  return (
                    <Container
                      key={uuid()}
                      width={140}
                    >
                      <Backbone
                        backgroundColor={getGlobalStyle(
                          '--color-background-gray-light'
                        )}
                        borderRadius={getGlobalStyle('--border-radius-xs')}
                        height={80}
                        width={100}
                      />
                    </Container>
                  )
                })}
              </Row>
            </Column>
          </Column>
        </BigScreen>
        <SmallScreen>
          <Container isWrap>
            <Column padding='0 16px'>
              <Spacer.Horizontal size={32} />
              <Backbone
                backgroundColor={getGlobalStyle(
                  '--color-background-gray-light'
                )}
                borderRadius={getGlobalStyle('--border-radius-xs')}
                height={46}
                width={100}
              />
              <Spacer.Horizontal size={20} />
              <Row width={279}>
                <Backbone
                  backgroundColor={getGlobalStyle(
                    '--color-background-gray-light'
                  )}
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={21}
                  width={100}
                />
              </Row>
              <Spacer.Horizontal size={28} />
              <Backbone
                backgroundColor={getGlobalStyle(
                  '--color-background-gray-light'
                )}
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={20}
                width={100}
              />
              <Spacer.Horizontal size={20} />
            </Column>
            <Column>
              <Row
                padding='0 0 0 16px'
                width={74}
              >
                <Backbone
                  backgroundColor={getGlobalStyle(
                    '--color-background-gray-light'
                  )}
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={12}
                  width={100}
                />
              </Row>
              <Spacer.Horizontal size={12} />
              <Row
                customClassName={styles.dayOptions___container}
                gap={4}
                padding='0 0 0 16px'
              >
                {Array.from(Array(6)).map(() => {
                  return (
                    <Container
                      key={uuid()}
                      minWidth='70px'
                      width={70}
                    >
                      <Backbone
                        backgroundColor={getGlobalStyle(
                          '--color-background-gray-light'
                        )}
                        borderRadius={getGlobalStyle('--border-radius-full')}
                        height={70}
                        width={100}
                      />
                    </Container>
                  )
                })}
              </Row>
              <Spacer.Horizontal size={24} />
              <Container
                isWrap
                padding='0 16px'
              >
                <Row width={74}>
                  <Backbone
                    backgroundColor={getGlobalStyle(
                      '--color-background-gray-light'
                    )}
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={12}
                    width={100}
                  />
                </Row>
                <Spacer.Horizontal size={18} />
                <Row
                  gap={28}
                  isWrap
                >
                  {Array.from(Array(6)).map(() => {
                    return (
                      <Container
                        key={uuid()}
                        width={95}
                      >
                        <Backbone
                          backgroundColor={getGlobalStyle(
                            '--color-background-gray-light'
                          )}
                          borderRadius={getGlobalStyle('--border-radius-xs')}
                          height={73}
                          width={100}
                        />
                      </Container>
                    )
                  })}
                </Row>
              </Container>
            </Column>
          </Container>
        </SmallScreen>
      </Column>
      <BigScreen>
        <Column
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          margin='0 0 0 57px'
          maxWidth='400px'
          padding='24px'
          position='sticky'
        >
          <ButtonGoToPay
            customStyle={true}
            isLoading={true}
          />
        </Column>
      </BigScreen>
    </Container>
  )
}
