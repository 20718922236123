import { Column, Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  currencyFormat,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface MyShipmentsTitleProps {
  deliveryInfo: {
    day: string
    hour: string
  }
  isMobile?: boolean | string
  price: string | number
  typeOfShipment: string
}

export const MyShipmentsTitle = ({
  deliveryInfo,
  isMobile,
  price,
  typeOfShipment = ''
}: MyShipmentsTitleProps): React.ReactElement => {
  const formatedPrice = currencyFormat({
    number: price ?? 0,
    bool: false
  })
  return (
    <Column>
      <Text
        color='black'
        fontSize={isMobile ? 'lg' : 'xl'}
        fontWeight='medium'
        id={getBemId('shipment', 'title')}
      >
        😎 Tú decides cuándo...
      </Text>
      <Spacer.Horizontal size={20} />
      <Row id={getBemId('shipment', 'container')}>
        <Text
          display='initial'
          fontSize='lg'
          id={getBemId('schedule', 'icon')}
          type='span'
        >
          ⏰ &nbsp;
        </Text>
        <Text
          color='black'
          display='initial'
        >
          <Text
            display='initial'
            fontSize='md'
            fontWeight='medium'
            id={getBemId('schedule', 'textType')}
            type='span'
          >
            {typeOfShipment}:&nbsp;
          </Text>
          <Text
            display='initial'
            fontSize='md'
            fontWeight='medium'
            id={getBemId('schedule', 'textDay')}
            type='span'
          >
            {deliveryInfo?.day},&nbsp;
          </Text>
          <Text
            display='initial'
            fontSize='md'
            id={getBemId('schedule', 'textHour')}
            type='span'
          >
            {deliveryInfo?.hour} |&nbsp;
          </Text>
          {formatedPrice === '$0' ? (
            <Text
              customColor={getGlobalStyle('--color-background-jade')}
              display='initial'
              fontSize='md'
              fontWeight='semibold'
              id={getBemId('schedule', 'textFree')}
              type='span'
            >
              Gratis 🥳
            </Text>
          ) : (
            <Text
              customColor={getGlobalStyle('--color-primary-light-red')}
              display='initial'
              fontSize='md'
              id={getBemId('schedule', 'textPrice')}
              type='span'
            >
              {formatedPrice}
            </Text>
          )}
        </Text>
      </Row>
    </Column>
  )
}
