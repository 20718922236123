import {
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import styles from './Swiper.module.css'

export interface BehaviorSwiper {
  open: () => void
  closed: () => void
  isOpen: boolean
}

interface SwiperListPorps {
  swiper?: boolean
  children?: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonComponent?: ReactElement<any>
  onChange?: (open: boolean) => void
}

enum Position {
  LEFT = 'left',
  RIGHT = 'right'
}

const threshold = 100 // required min distance traveled to be considered swipe
const allowedTime = 300 // maximum time allowed to travel that distance

export const Swiper = forwardRef(
  (
    {
      swiper = true,
      buttonComponent,
      children,
      onChange = () => {
        return
      }
    }: SwiperListPorps,
    ref
  ) => {
    const refContent = useRef<HTMLDivElement>()
    const { current: container } = refContent
    const startX = useRef(0)
    const startTime = useRef(0)
    const distX = useRef(0)
    const elapsedTime = useRef(0)
    const swipedir = useRef(null)
    const contentChild = useRef<HTMLDivElement>()
    const [statusSwiper, setStatusSwiper] = useState(false)
    const heightChildren = useRef(0)

    const open = () => {
      contentChild.current.style.transform = 'translateX(-170px)'
      swipedir.current = Position.LEFT
      setStatusSwiper(true)
      onChange(true)
    }

    const closed = () => {
      contentChild.current.style.transform = null
      swipedir.current = Position.RIGHT
      setStatusSwiper(false)
      onChange(false)
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          open() {
            open()
          },
          closed() {
            closed()
          },
          get isOpen() {
            return statusSwiper
          }
        }
      },
      [statusSwiper]
    )

    useEffect(() => {
      heightChildren.current =
        contentChild.current?.getBoundingClientRect()?.height
    }, [])

    useEffect(() => {
      if (!container && !swiper) return undefined

      const touchStart = (event: TouchEvent) => {
        const touchobj = event.changedTouches[0]
        startX.current = touchobj.pageX // get start horizontal dist
        startTime.current = new Date().getTime() // the initial time is saved
      }

      const touchMove = (event: TouchEvent) => {
        const touchobj = event.changedTouches[0]
        distX.current = touchobj.pageX - startX.current // get horizontal dist traveled by finger while in contact with surface
        elapsedTime.current = new Date().getTime() - startTime.current // calculates the difference of the times
        // first condition for horizontal swipe met 2nd condition for awipe met
        if (
          elapsedTime.current <= allowedTime &&
          Math.abs(distX.current) >= threshold
        ) {
          const newValue = distX.current < 0 ? Position.LEFT : Position.RIGHT
          //the following two validations, when calculating the new value is the same no change is made
          if (!newValue) {
            return
          }

          if (newValue === swipedir.current) {
            return
          }
          // the new offset is assigned
          swipedir.current = newValue
          // depending on the calculation of the direction it opens or closes.
          if (swipedir.current === Position.LEFT) {
            open()
          } else {
            closed()
          }
        }
      }

      container?.addEventListener('touchstart', touchStart)

      container?.addEventListener('touchmove', touchMove)

      return () => {
        document?.removeEventListener('touchstart', touchStart)
        document?.removeEventListener('touchmove', touchMove)
      }
    }, [refContent.current, swiper])

    return (
      <div
        ref={refContent}
        style={{
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          position: 'relative',
          zIndex: 1
        }}
      >
        <div
          className={styles.containerChild}
          data-testid='contentChild'
          ref={contentChild}
        >
          {children}
        </div>
        {swiper && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              marginTop: '1px',
              height: `${heightChildren.current}px`
            }}
          >
            {buttonComponent}
          </div>
        )}
      </div>
    )
  }
)

Swiper.displayName = 'Swiper'
