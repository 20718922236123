import NextImage from 'next/image'
import { Row, Column, Picture } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface ImageProps {
  srcHighlight?: string
  altHighlight?: string
  srcPicture: string
  altPicture: string
}

type ImageLoader = {
  src: string
}

const imageNextJsLoader = ({ src }: ImageLoader) => {
  return `${src}&q=75`
}

export const Image = ({
  srcHighlight,
  altHighlight,
  srcPicture,
  altPicture
}: ImageProps): React.ReactElement<ImageProps> => {
  return (
    <Column>
      {/* promotional flags */}
      <Row>
        {srcHighlight && (
          <Picture
            alt={altHighlight}
            border='none'
            borderRadius='none'
            height='auto'
            src={srcHighlight}
            width='auto'
          />
        )}
      </Row>

      {/* Desktop */}
      <BigScreen>
        <Row justifyContent='start'>
          <Column
            maxHeight='80px'
            maxWidth='80px'
          >
            <NextImage
              alt={altPicture}
              height={80}
              loader={imageNextJsLoader}
              objectFit='cover'
              src={srcPicture}
              width={80}
            />
          </Column>
        </Row>
      </BigScreen>

      {/* Mobile */}
      <SmallScreen>
        <Row justifyContent='center'>
          <Column
            minHeight='50px'
            minWidth='50px'
          >
            <NextImage
              alt={altPicture}
              height={50}
              loader={imageNextJsLoader}
              objectFit='cover'
              src={srcPicture}
              width={50}
            />
          </Column>
        </Row>
      </SmallScreen>
    </Column>
  )
}
