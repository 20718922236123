import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { RestockedModalDesktop } from './RestockedModalDesktop'
import { RestockedModalMobile } from './RestockedModalMobile'
import { IRestockedProducts } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostStockAvailability'

interface RestockedModalProps {
  restockedProducts: IRestockedProducts
  handleClose: () => void
}

export const RestockedModal = ({
  restockedProducts,
  handleClose
}: RestockedModalProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <RestockedModalDesktop
          handleClose={handleClose}
          restockedProducts={restockedProducts}
        />
      </BigScreen>
      <SmallScreen>
        <RestockedModalMobile
          handleClose={handleClose}
          restockedProducts={restockedProducts}
        />
      </SmallScreen>
    </>
  )
}
